import React from "react";
import styled from "styled-components";

type TitleSubtitleProps = {
  title: string;
  subtitle: string | number;
};

const TotalsRowTitle = styled.div`
  font-size: 0.75rem;
`;

const Subtitle = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.875rem;
`;

export const TotalsRow = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 22rem;
`;

export const TitleSubtitle: React.FC<TitleSubtitleProps> = ({ title, subtitle }) => {
  return (
    <div>
      <TotalsRowTitle>{title}</TotalsRowTitle>
      <Subtitle>{subtitle}</Subtitle>
    </div>
  );
};

export const AltinnInfoLink: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <a href="https://uidp-admin.udir.no/info/altinn" target="_blank" rel="noreferrer">
    {children}
  </a>
);
