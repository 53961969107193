import { SelectFilter, Table, TextFilter } from "@utdanningsdirektoratet/table";
import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { AktorModel, HomeClient } from "../../../ApiClients";
import routes from "../../../routes";
import { getCurrentUser } from "../../../utils/globalFunctions";

const TavlaTilgang: React.FC<{ orgNr: string }> = ({ orgNr }) => {
  const user = getCurrentUser();

  const { data: tilganger } = useSWR(!user?.organizations.find((org) => org.number === orgNr)?.isActive ? null : `tilganger/${orgNr}`, async () => {
    const client = new HomeClient();
    return client.getAltinnPermissions(orgNr);
  });

  if (tilganger?.hasReadWrite) return <>Skrive- og lesetilgang</>;
  if (tilganger?.hasRead) return <>Lesetilgang</>;

  return null;
};

export const VelgAktorOrganisasjonTable: React.FC<{ data: Omit<AktorModel, "status">[] }> = ({ data }) => {
  const navigate = useNavigate();

  const aktorStatusOptions = [
    { text: "Alle", value: "" },
    { text: "Ja", value: "true" },
    { text: "Nei", value: "false" },
  ];

  return (
    <Table
      columns={[
        {
          header: "Aktør",
          key: "navn",
          sort: true,
          tooltip: false,
          filter: TextFilter,
        },
        {
          header: "Orgnummer",
          key: "orgNr",
          sort: true,
          tooltip: false,
          filter: TextFilter,
        },
        {
          header: "Finnes i Tavla",
          key: "isActive",
          data: (cell) => (cell ? "Ja" : "Nei"),
          sort: true,
          tooltip: false,
          filter: (props) => <SelectFilter {...props} options={aktorStatusOptions} />,
        },
        {
          header: "Tilgang i Tavla",
          key: "orgNr",
          data: (cell) => <TavlaTilgang orgNr={cell}></TavlaTilgang>,
        },
      ]}
      data={data}
      options={{
        rowOnClick: (row) => (row.isSchool ? navigate(routes.dashboard(row.orgNr, "Oversikt")) : navigate(routes.dashboard(row.orgNr, "Tilskudd"))),
      }}
    ></Table>
  );
};
