import styled from "styled-components";
import { mediaqueries } from "@utdanningsdirektoratet/styles";
import colors from "@utdanningsdirektoratet/styles/colors";

export const PagePanel = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 2rem 5rem;
  @media ${mediaqueries.small} {
    padding: 2rem 1rem;
  }
  background-color: ${(props) => props.backgroundColor ?? colors.white};
`;
