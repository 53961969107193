import { IconButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Box } from "@utdanningsdirektoratet/layout";
import styled from "styled-components";
import useLocalStorageState from "use-local-storage-state";
import { LocalStorageKeys } from "../../../../utils/localstorageKeys";

const StyledBox = styled(Box)`
  margin: 0 -6rem;

  margin-top: 2rem;
  padding: 2rem;
  .infobox {
    display: flex;
    gap: 1rem;
    p {
      margin: 1rem 0;
    }
    span {
      display: inline-block;
    }
  }
`;

export const AccessInformationBox: React.FC = () => {
  const [isHidden, setIsHidden] = useLocalStorageState<boolean>(LocalStorageKeys.HideAccessInformationBox, { defaultValue: false });

  if (isHidden) return null;
  return (
    <StyledBox background="eggshell4" icon="circle info">
      <div className="infobox">
        <div>
          <b>Tilganger i Altinn</b>
          <p>
            Alle tilsyn er tilgjengelig på tilsyn.udir.no, men for å lese usladdede rapporter og sende inn dokumentasjon må man ha Altinn-tilgang.
          </p>
          <span>Med Tilsyn - Lesetilgang kan man lese usladdede tilsynsrapporter.</span>
          <span>Med Tilsyn - Lese- og skrivetilgang kan man også sende inn dokumentasjon og uttalelser i tilsynet.</span>
        </div>
        <div>
          <IconButton onClick={() => setIsHidden(true)} aria-label="lukk informasjonsboks">
            <Icon icon="cross" />
          </IconButton>
        </div>
      </div>
    </StyledBox>
  );
};
