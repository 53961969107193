import React from "react";
import { DetailedListRow, DetailedListCell } from "@utdanningsdirektoratet/detailedlist";
import { Link, useParams } from "react-router-dom";
import routes from "../../../../routes";
import { OrdningerForAar } from "../../../../ApiClients";
import { formatCurrency } from "../../../../utils/numberUtils";
import { OrgNrParamType } from "../../../../utils/globalFunctions";

type DelvisUtbetaltRowProps = {
  tilskudd: OrdningerForAar;
  aktorName?: string;
  budsjettAar: number;
};

export const DelvisUtbetaltRow: React.FC<DelvisUtbetaltRowProps> = ({ tilskudd, aktorName, budsjettAar }) => {
  const { orgnr } = useParams<OrgNrParamType>();
  const utbetaltInnbetaltLabel = tilskudd.utbetalt >= 0 ? "Utbetalt" : "Innbetalt";

  const isGodkjentOrHasGjennomforteBetalinger = tilskudd.isGodkjent || tilskudd.utbetalt > 0 || tilskudd.innbetalt > 0;
  const total = tilskudd.isGodkjent ? formatCurrency(tilskudd.innvilgetBelop) : "Udir beregner total";

  const avCell = tilskudd.isGodkjent ? (
    <DetailedListCell size="50px" right>
      av
    </DetailedListCell>
  ) : null;

  const totaltTilskuddCellSize = tilskudd.isGodkjent ? "140px" : "190px";
  return (
    <DetailedListRow>
      {({ className }) => {
        return (
          <Link
            className={className}
            to={routes.grunnlag(orgnr!, budsjettAar.toString(), tilskudd.ordningId.toString(), tilskudd.isFromNewVertikal.toString())}
          >
            <DetailedListCell>
              {tilskudd.ordningNavn} {aktorName}
            </DetailedListCell>
            <DetailedListCell size="30%" right>
              {isGodkjentOrHasGjennomforteBetalinger && utbetaltInnbetaltLabel}
            </DetailedListCell>
            <DetailedListCell size="140px" bold right>
              {isGodkjentOrHasGjennomforteBetalinger && formatCurrency(tilskudd.utbetalt)}
            </DetailedListCell>
            {avCell}
            <DetailedListCell size={totaltTilskuddCellSize} bold right>
              {total}
            </DetailedListCell>
          </Link>
        );
      }}
    </DetailedListRow>
  );
};
