import React from "react";
import { Heading1, colors } from "@utdanningsdirektoratet/styles";
import { Icon } from "@utdanningsdirektoratet/icon";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { Collapsible } from "@utdanningsdirektoratet/collapsible";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { hide, show } from "@utdanningsdirektoratet/slowaction";
import { InpageMessage } from "@utdanningsdirektoratet/inpagemessage";
import { Grunnlag } from "./Grunnlag";
import { Beregning } from "./Beregning";
import { Tilskuddsbrev } from "./Tilskuddsbrev";
import { PagePanel } from "../../../../../layout/PagePanels";
import routes from "../../../../../routes";
import { Forfallsplan } from "./Forfallsplan";
import { TilskuddClient, TilskuddsDetaljer, Tilskuddsgrunnlag } from "../../../../../ApiClients";
import { useCurrentOrganization } from "../../../../../utils/globalFunctions";

const Wrapper = styled.div`
  max-width: 100rem;
`;

const Header = styled.div`
  width: 100%;
`;

const CollapsibleWrapper = styled.div`
  width: 100%;
`;

const getTitle = (grunnlag: Tilskuddsgrunnlag) => {
  if (grunnlag.startFraDato && grunnlag.sluttTilOgMedDato) {
    return `${grunnlag.tittel} (${formatDate(grunnlag.startFraDato, "dd.MM.yyyy")} - ${formatDate(grunnlag.sluttTilOgMedDato, "dd.MM.yyyy")})`;
  }

  return `${grunnlag.tittel}`;
};

const getErrorMessage = () => {
  return (
    <div>
      <b>Vi kunne ikke hente informasjon om denne ordningen</b>
      <div>
        Vi jobber med å løse problemet. I mellomtiden kan du ta kontakt med oss på <a href="mailto: post@udir.no">post@udir.no</a>.
      </div>
    </div>
  );
};

export const TilskuddsGrunnlag: React.FC = () => {
  const [tilskuddsDetaljer, setTilskuddsDetaljer] = React.useState<TilskuddsDetaljer>();
  const { budsjettAar, ordningId, isFromNewVertikal } = useParams<{
    budsjettAar: string;
    ordningId: string;
    isFromNewVertikal: string;
  }>();
  const [error, setError] = React.useState(false);
  const currentOrganization = useCurrentOrganization();

  React.useEffect(() => {
    const get = async () => {
      if (!currentOrganization?.udirAktorId || !budsjettAar || !ordningId) return;
      try {
        show();
        const client = new TilskuddClient();
        const result = await client.getTilskuddsDetaljer(currentOrganization.udirAktorId, +budsjettAar, +ordningId, isFromNewVertikal === "true");
        setTilskuddsDetaljer(result);
      } catch {
        setError(true);
      } finally {
        hide();
      }
    };
    get();
  }, [budsjettAar, ordningId, currentOrganization?.udirAktorId, isFromNewVertikal]);

  if (error) return <InpageMessage message={getErrorMessage()} type="warning" />;

  if (!tilskuddsDetaljer) return null;

  const allTilskuddGodkjent = tilskuddsDetaljer.tilskuddsgrunnlag.every((t) => t.isGodkjent);

  return (
    <PagePanel backgroundColor={colors.lightgrey}>
      <Wrapper>
        <Header>
          <Link to={currentOrganization != null ? routes.dashboard(currentOrganization.number, "Tilskudd") : routes.home}>
            <Icon icon="arrow left long" type="wide" placement="before" />
            Tilbake
          </Link>
          <Heading1 className="u--marginBottom2 u--marginTop2">
            {tilskuddsDetaljer.tilskuddsgrunnlag &&
              tilskuddsDetaljer.tilskuddsgrunnlag?.length > 0 &&
              `${tilskuddsDetaljer.tilskuddsgrunnlag[0].kapittel}.${tilskuddsDetaljer.tilskuddsgrunnlag[0].post} - `}
            {tilskuddsDetaljer.ordningNavn} ({budsjettAar})
          </Heading1>
        </Header>
        {tilskuddsDetaljer.tilskuddsgrunnlag === null ||
          (tilskuddsDetaljer.tilskuddsgrunnlag.length === 0 && (
            <InpageMessage
              message={
                <>
                  <div>Finner ikke tilskuddsdata for valgt år</div>
                  <div>
                    Undersøk om ordningen har tilskuddsdata og utbetalinger under tidligere år. Dersom du ikke finner det, ta konmtakt med oss på{" "}
                    <a href="mailto: post@udir.no">post@udir.no</a>
                  </div>
                </>
              }
              type="info"
            />
          ))}
        {tilskuddsDetaljer.tilskuddsgrunnlag.map((grunnlag, index) => {
          return (
            <div key={index}>
              <CollapsibleWrapper className="u--marginBottom1">
                <Collapsible title="Brev" color="steelblue" openByDefault={false} disableLocalStorage>
                  <Tilskuddsbrev kildetabell={grunnlag.kildetabell} kildeId={grunnlag.kildetabellId} />
                </Collapsible>
              </CollapsibleWrapper>
              <CollapsibleWrapper className="u--marginBottom3">
                <Collapsible title={getTitle(grunnlag)} color="white">
                  {grunnlag.isGodkjent ? (
                    <>
                      <Grunnlag tilskuddsGrunnlag={grunnlag} />
                      <Beregning tilskuddsGrunnlag={grunnlag} className="u--marginTop3" />
                    </>
                  ) : (
                    <InpageMessage className="u--marginTop1" message="Tilskuddet er under beregning av Udir." type="info" />
                  )}
                </Collapsible>
              </CollapsibleWrapper>
            </div>
          );
        })}
        <Forfallsplan tilskuddsDetaljer={tilskuddsDetaljer} tilskuddGodkjent={allTilskuddGodkjent} className="u--marginTop3" />
      </Wrapper>
    </PagePanel>
  );
};
