import { Button } from "@utdanningsdirektoratet/button";
import styled from "styled-components";

export const Container = styled.div<{ top?: string; bottom?: string }>`
  margin-bottom: ${(p) => (p.bottom ? p.bottom : "2rem")};
  margin-top: ${(p) => (p.top ? p.top : "0")};
  width: min(50rem, 100%);
`;

export const CardWrapper = styled.div`
  margin-bottom: 1rem;
`;
export const RightDiv = styled.div`
  text-align: right;
`;
export const StyledButton = styled(Button)`
  margin-left: 1rem;
`;
