// eslint-disable-next-line import/no-cycle
import { useParams } from "react-router-dom";
import type { LoggedInUserViewModel, PreloadedConfigModel, PreloadedViewModel } from "../ApiClients";

declare global {
  interface Window {
    initialState: PreloadedViewModel;
  }
}

interface AntiForgeryTokenInfo {
  tokenHeaderName: string;
  requestToken: string;
}

export const getAntiForgeryTokenInfo = (): AntiForgeryTokenInfo => window.initialState.antiForgeryTokenInfo;
export const getCurrentUser = (): LoggedInUserViewModel | null | undefined => window.initialState.currentUser;
export const getConfig = (): PreloadedConfigModel => window.initialState.config;

export const useCurrentOrganization = () => {
  const { orgnr } = useParams<OrgNrParamType>();
  const currentUser = getCurrentUser();

  return currentUser?.organizations.find((o) => o.number === orgnr);
};

export type OrgNrParamType = {
  orgnr: string;
};

export type TabTypes = "Oversikt" | "Tilskudd" | "Tilbud";
