import * as React from "react";
import { Error } from "@utdanningsdirektoratet/error";
import { ApiException } from "../ApiClients";

type ErrorProps = {
  error: ApiException;
};

const ErrorPage: React.FC<ErrorProps> = (props: ErrorProps) => {
  if (props.error.status === 401) {
    window.location.reload();
    return null;
  }

  return <Error status={props.error.status} />;
};

export default ErrorPage;
