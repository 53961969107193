import React from "react";
import useSWRImmutable from "swr/immutable";
import { MainPanel } from "@utdanningsdirektoratet/layout";
import { getCurrentUser } from "../../utils/globalFunctions";
import { usePageViewTrackingWithoutAccessInfo } from "../../utils/analyticsUtils";
import { AktorClient } from "../../ApiClients";
import { PagePanel } from "../../layout/PagePanels";
import { VelgAktorOrganisasjonTable } from "./components/VelgAktorOrganisasjonTable";
import { VelgAktorSaksbehandlerTable } from "./components/VelgAktorSaksbehandlerTable";

export const VelgAktør: React.FC = () => {
  const user = getCurrentUser();
  const hasRoller = user && user.roller.length > 0;

  usePageViewTrackingWithoutAccessInfo()

  const { data: allAktors = [] } = useSWRImmutable(hasRoller ? "aktorer" : null, async () => {
    const client = new AktorClient();
    return client.index();
  });

  const allUserOrganizations = user!.organizations.map((o) => ({
    navn: o.name,
    orgNr: o.number,
    isActive: o.isActive,
    isSchool: o.isSchool,
    isGodkjentIkkeStartet: o.isGodkjentIkkeStartet,
    id: o.number,
  }));

  return (
    <PagePanel>
      <MainPanel color="white">
        <h1>Velg aktør</h1>
        {hasRoller ? <VelgAktorSaksbehandlerTable data={allAktors} /> : <VelgAktorOrganisasjonTable data={allUserOrganizations} />}
      </MainPanel>
    </PagePanel>
  );
};
