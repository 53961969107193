import { format } from "date-fns/format";
import { nb } from "date-fns/locale/nb";

const longDateFormat = "d. MMMM yyyy";

export const formatLongDate = (date: Date) => {
  return date ? format(date, longDateFormat, { locale: nb }) : "";
};

type SortOrder = "asc" | "dsc";

export const sortDate = (a: Date, b: Date, sortOrder: SortOrder = "asc"): number => {
  if (sortOrder === "asc") {
    if (a > b) return 1;
    if (a === b) return 0;
    return -1;
  }
  if (a > b) return -1;
  if (a === b) return 0;
  return 1;
};
