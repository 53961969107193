import { Card } from "@utdanningsdirektoratet/card";
import { formatDate, parseDate } from "@utdanningsdirektoratet/utils/date";
import { isAfter } from "date-fns/isAfter";
import React from "react";
import styled from "styled-components";
import { Placeholder } from "@utdanningsdirektoratet/placeholder";
import {
  AarsregnskapstilsynViewModel,
  InnsamlingApiResponse,
  PublisertInnsamlingStatus,
  SoknadMetadataViewModel,
  StatusEnum,
  StatusModel,
  TilsynViewModel,
  TilsynsStatus,
} from "../../../../ApiClients";
import { PaddedHeading1, PaddedHeading2 } from "../../../../components/Headings";
import { sortDate } from "../../../../utils/dateUtils";
import { getConfig, useCurrentOrganization } from "../../../../utils/globalFunctions";
import { AccessInformationBox } from "./AccessInformationBox";
import { CardWrapper, Container } from "./SakStyles";
import { Sak } from "./sakTypes";

const SakWrapper = styled.div`
  margin: 0.5rem 0 2rem;
`;

const isInnsendtSoknad = (lastStatus: StatusModel, allStatus: StatusModel[]) => {
  if (lastStatus.status === StatusEnum.Processing) return true;
  if (lastStatus.status === StatusEnum.Sent) return true;
  if (lastStatus.status === StatusEnum.FerdigBehandlet) return false;
  if (lastStatus.status === StatusEnum.Imported) {
    return !allStatus.some((s) => s.status === StatusEnum.Withdrawn);
  }
  return false;
};

const getSakCards = (saks: Sak[]) => {
  return saks.map((sak: Sak) => {
    return (
      <CardWrapper key={sak.id}>
        <Card color={sak.color} title={sak.title} leftLabel={sak.label} status={sak.datoText} href={sak.url} />
      </CardWrapper>
    );
  });
};

const createSoknads = (soknader: SoknadMetadataViewModel[], aktorId: string, isInnsendt: boolean): Sak[] => {
  return soknader.map((s) => {
    // eslint-disable-next-line no-nested-ternary
    const datoText = isInnsendt
      ? `Innsendt: ${formatDate(s.created, "dd.MM.yyyy")}`
      : s.lastStatus.status === StatusEnum.Draft && s.lastStatus.created < s.lastModified
        ? `Sist endret: ${formatDate(s.lastModified, "dd.MM.yyyy")}`
        : `Opprettet: ${formatDate(s.created, "dd.MM.yyyy")}`;
    return {
      id: s.id,
      title: s.formTitle ?? "",
      label: "Gå til søknad",
      dato: new Date(s.lastModified ?? s.created),
      datoText,
      url: `${getConfig().skolesoknadUrl}/${aktorId}/soknad/${s.id}`,
      color: isInnsendt ? "blue" : "yellow",
    } as const;
  });
};

const createInnsamlinger = (innsamlinger: InnsamlingApiResponse[], isInnsendt: boolean): Sak[] => {
  return innsamlinger.map(
    (i) =>
      ({
        id: i.externalId,
        title: i.tittel,
        label: "Gå til innsamling",
        dato: parseDate(i.frist),
        datoText: `Frist: ${formatDate(i.frist, "dd.MM.yyyy")}`,
        url: `${getConfig().innsamlingUrl}/innsamling/${i.externalId}`,
        color: isInnsendt ? "blue" : "yellow",
      }) as const,
  );
};

const createTilsyn = (tilsyn: TilsynViewModel[]): Sak[] => {
  return tilsyn.map(
    (t) =>
      ({
        id: t.externalId,
        title: t.name,
        label: "Gå til tilsyn",
        dato: new Date(t.publisertDateUtc),
        datoText: t.publiseringType,
        url: `${getConfig().tilsynUrl}/tilsyn/${t.externalId}`,
        color: "lightazure",
      }) as const,
  );
};

const createAarsregnskapstilsyn = (aarsregnskapstilsyn: AarsregnskapstilsynViewModel[], aktorId: string): Sak[] => {
  return aarsregnskapstilsyn.map((s) => {
    const isPaabegynt = s.soknadId !== null;
    const isInnsendt: boolean = s.allStatus.length > 0 ? isInnsendtSoknad(s.lastStatus!, s.allStatus) : false;
    const datoText = isInnsendt ? `Innsendt: ${formatDate(s.created!, "dd.MM.yyyy")}` : `Send inn dokumentasjon: Frist ${s.aarsregnskapstilsynFrist}`;
    return {
      id: s.soknadId ?? `${s.formTitle}-${s.aarsregnskapstilsynFrist}`,
      title: s.formTitle ?? "",
      label: "Gå til skjema",
      dato: s.soknadId ? new Date(s.lastModified! ?? s.created!) : new Date(), // else: nå-dato putter årsregnskapstilsyn som ikke er påbegynt øverst
      datoText,
      url: isPaabegynt
        ? `${getConfig().skolesoknadUrl}/${aktorId}/soknad/${s.soknadId}`
        : `${getConfig().skolesoknadUrl}/${aktorId}/aarsregnskapstilsyn`,
      color: isInnsendt ? "blue" : "yellow",
    } as Sak;
  });
};

const getAktiveSaker = (
  _soknader: SoknadMetadataViewModel[],
  _innsamlinger: InnsamlingApiResponse[],
  _tilsyn: TilsynViewModel[],
  aktorId: string,
) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const pagaendeSoknader = _soknader.filter((c) => c.lastStatus.status === StatusEnum.Draft);
  const innsendteSoknader = _soknader.filter((c) => isInnsendtSoknad(c.lastStatus, c.allStatus));
  const pagaendeInnsamlinger = _innsamlinger.filter((i) => !(i.status === PublisertInnsamlingStatus.Avsluttet || isAfter(today, new Date(i.frist))));
  const innsendteInnsamlinger = _innsamlinger.filter((i) => !(i.status === PublisertInnsamlingStatus.Avsluttet) && isAfter(today, new Date(i.frist)));
  const _pagaendeTilsyn = _tilsyn?.filter(
    (t) =>
      t.status === TilsynsStatus.DokumentasjonskravPublisert ||
      t.status === TilsynsStatus.ForelopigTilsynsrapportPublisert ||
      t.status === TilsynsStatus.TilsynsrapportPublisert,
  );
  const _innsendteTilsyn = _tilsyn?.filter(
    (t) =>
      !(
        t.status === TilsynsStatus.DokumentasjonskravPublisert ||
        t.status === TilsynsStatus.ForelopigTilsynsrapportPublisert ||
        t.status === TilsynsStatus.TilsynsrapportPublisert ||
        t.status === TilsynsStatus.TilsynAvsluttet
      ), // Alle andre statuser enn de som er under påbegynt eller avsluttet
  );

  const soknader: Sak[] = createSoknads(pagaendeSoknader, aktorId, false)
    .concat(createSoknads(innsendteSoknader, aktorId, true))
    .sort((a, b) => sortDate(a.dato, b.dato, "dsc"));

  const innsamlinger: Sak[] = createInnsamlinger(pagaendeInnsamlinger, false)
    .concat(createInnsamlinger(innsendteInnsamlinger, true))
    .sort((a, b) => sortDate(a.dato, b.dato, "dsc"));

  const tilsyn: Sak[] = createTilsyn(_pagaendeTilsyn)
    .concat(createTilsyn(_innsendteTilsyn))
    .sort((a, b) => sortDate(a.dato, b.dato, "dsc"));

  return { soknader, innsamlinger, tilsyn };
};

const getAarsregnskapstilsyn = (aarsregnskapstilsyn: AarsregnskapstilsynViewModel[], aktorId: string) => {
  return createAarsregnskapstilsyn(aarsregnskapstilsyn, aktorId).sort((a, b) => sortDate(a.dato, b.dato, "dsc"));
};

export const AktiveSaker: React.FC<{
  soknader: SoknadMetadataViewModel[] | undefined;
  innsamlinger: InnsamlingApiResponse[] | undefined;
  tilsyn: TilsynViewModel[] | undefined;
  aarsregnskapstilsyn: AarsregnskapstilsynViewModel[] | undefined;
}> = ({ soknader, innsamlinger, tilsyn, aarsregnskapstilsyn }) => {
  const currentOrganization = useCurrentOrganization();

  const {
    soknader: soknadSaker,
    innsamlinger: innsamlingSaker,
    tilsyn: tilsynSaker,
  } = React.useMemo(
    () => getAktiveSaker(soknader ?? [], innsamlinger ?? [], tilsyn ?? [], currentOrganization!.udirAktorId!),
    [soknader, innsamlinger, tilsyn, currentOrganization],
  );
  const aarsregnskapstilsynSaker: Sak[] = getAarsregnskapstilsyn(aarsregnskapstilsyn ?? [], currentOrganization!.udirAktorId!);

  const hasSoknader = soknadSaker.length !== 0;
  const hasInnsamlinger = innsamlingSaker.length !== 0;
  const hasTilsyn = tilsynSaker.length !== 0;
  const hasAarsregnskapstilsyn = aarsregnskapstilsynSaker.length !== 0;

  const isLoadingAnyData = soknader === undefined || innsamlinger === undefined || tilsyn === undefined || aarsregnskapstilsyn === undefined;

  return (
    <Container top="2rem">
      <PaddedHeading1>Pågående saker</PaddedHeading1>
      <p>Her ser du pågående saker innen tilsyn, innsamling og søknader for skoler godkjent etter privatskoleloven.</p>
      {!hasTilsyn && !hasInnsamlinger && !hasSoknader && !hasAarsregnskapstilsyn && !isLoadingAnyData && (
        <p>
          <i>Du har ingen pågående saker</i>
        </p>
      )}
      {tilsyn === undefined || aarsregnskapstilsyn === undefined ? (
        <Placeholder height="10" />
      ) : (
        (hasTilsyn || hasAarsregnskapstilsyn) && (
          <SakWrapper>
            <PaddedHeading2>Tilsyn</PaddedHeading2>
            {getSakCards(aarsregnskapstilsynSaker)}
            {getSakCards(tilsynSaker)}
            <AccessInformationBox />
          </SakWrapper>
        )
      )}
      {innsamlinger === undefined ? (
        <Placeholder height="10" />
      ) : (
        hasInnsamlinger && (
          <SakWrapper>
            <PaddedHeading2>Innsamlinger</PaddedHeading2>
            {getSakCards(innsamlingSaker)}
          </SakWrapper>
        )
      )}
      {soknader === undefined ? (
        <Placeholder height="10" />
      ) : (
        hasSoknader && (
          <SakWrapper>
            <PaddedHeading2>Søknader</PaddedHeading2>
            {getSakCards(soknadSaker)}
          </SakWrapper>
        )
      )}
    </Container>
  );
};
