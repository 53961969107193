import React from "react";
import styled from "styled-components";
import { Heading3 } from "@utdanningsdirektoratet/styles";
import { FagkodeModel, LaereplanModel, TilbudClient } from "../../../../../ApiClients";
import Section from "../../../../../components/Section";
import { PaddedHeading2 } from "../../../../../components/Headings";

type LaereplanProps = {
  laereplan?: LaereplanModel | null;
};

const FagkodeContainer = styled.div`
  background-color: var(--lightazure4);
  padding: 1rem;
  margin-bottom: 0.5rem;
`;

const FagkodeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Details = styled.div`
  flex: 1;
  padding-right: 2rem;
`;

const Merknader = styled.div`
  display: flex;
  flex: 2;
`;

const Inline = styled.div`
  display: flex;
`;

const Laereplan: React.FC<LaereplanProps> = ({ laereplan }) => {
  const [fagkoder, setFagkoder] = React.useState<Array<FagkodeModel>>([]);

  React.useEffect(() => {
    if (!laereplan) return;

    const client = new TilbudClient();
    client.getFagkoder().then(setFagkoder); // TODO catch
  }, [laereplan]);

  if (!laereplan)
    return (
      <Section>
        <PaddedHeading2>Læreplan</PaddedHeading2>
        <i>Ingen læreplan er satt</i>
      </Section>
    );
  const lareplanType = laereplan.laereplantype ? `${laereplan.laereplantype.kategori} - ${laereplan.laereplantype.tittel}` : "";
  let fagkoderInUse = fagkoder.filter((f) => laereplan.fagkoder?.includes(f.kode));

  if (fagkoderInUse.length !== laereplan.fagkoder?.length) {
    fagkoderInUse =
      laereplan.fagkoder?.map((f) => ({
        kode: f,
        tittel: "",
        tilleggsopplysninger: "",
      })) ?? [];
  }

  return (
    <Section>
      <PaddedHeading2>Læreplan</PaddedHeading2>
      <div>{lareplanType !== "" ? `Skolen følger ${lareplanType}` : <i>Ingen læreplan er satt</i>}</div>
      <div className="u--marginTop2">{laereplan.kommentar}</div>
      {laereplan.fagkoder && laereplan.fagkoder.length > 0 && (
        <div className="u--marginTop2">
          <div className="u--bold u--paddingBottom1">Godkjente tilleggsfag</div>
          {fagkoderInUse.map((t) => (
            <FagkodeContainer key={t.kode}>
              <Heading3 className="u--marginBottom1-5">{t.tittel}</Heading3>
              <FagkodeWrapper>
                <Details>
                  <Inline>
                    <div className="u--label u--marginBottom0-5 u--paddingRight1">Fagkode</div>
                    <div className="u--marginBottom0-5">{t.kode}</div>
                  </Inline>
                  <Inline>
                    <div className="u--label u--marginBottom0-5 u--paddingRight1">Årstimer</div>
                    <div className="u--marginBottom0-5">{t.omfang}</div>
                  </Inline>
                </Details>
                <Merknader>
                  <div className="u--label u--paddingRight1">Merknad</div>
                  <div>{t.tilleggsopplysninger}</div>
                </Merknader>
              </FagkodeWrapper>
            </FagkodeContainer>
          ))}
        </div>
      )}
    </Section>
  );
};

export default Laereplan;
