import { LinkBox, LinkBoxContainer } from "@utdanningsdirektoratet/linkbox";
import React from "react";
import styled from "styled-components";
import { colors } from "@utdanningsdirektoratet/styles";
import { getConfig, useCurrentOrganization } from "../../../utils/globalFunctions";
import { PagePanel } from "../../../layout/PagePanels";

const StyledLinkBoxContainer = styled(LinkBoxContainer)`
  display: flex;
  width: 28rem;
`;

type Props = {
  udirAktorId?: string | null;
  backgroundColor?: string;
};

export const SkolesoknadLinkBox: React.FC<Props> = ({ udirAktorId, backgroundColor = colors.lightgrey }) => {
  const currentOrganization = useCurrentOrganization();
  const text = currentOrganization?.isGodkjentIkkeStartet ? "Søk om driftstillatelse" : "Søk om endring av tilbud";
  return (
    <PagePanel backgroundColor={backgroundColor}>
      <StyledLinkBoxContainer>
        <LinkBox color="green" text={text} href={`${getConfig().skolesoknadUrl}/${udirAktorId}`} />
      </StyledLinkBoxContainer>
    </PagePanel>
  );
};
