import React from "react";
import { DetailedList, DetailedListRow, DetailedListCell } from "@utdanningsdirektoratet/detailedlist";
import styled from "styled-components";
import { AltUtbetaltRow } from "./AltUtbetaltRow";
import { DelvisUtbetaltRow } from "./DelvisUtbetaltRow";
import { OrdningerForAar, TilskuddsOverview } from "../../../../ApiClients";
import { Tilskuddoppsummering } from "./Tilskuddoppsummering";

const Wrapper = styled.div`
  margin-bottom: 2.5rem;
`;

type TilskuddForAarProps = {
  tilskuddOverview: TilskuddsOverview;
  budsjettAar: number;
};

const getAktorName = (tilskudd: OrdningerForAar) =>
  tilskudd.sluttmottakerAktorNavn !== tilskudd.tilskuddsmottakerNavn ? ` — ${tilskudd.sluttmottakerAktorNavn}` : undefined;

export const TilskuddForAar: React.FC<TilskuddForAarProps> = ({ tilskuddOverview, budsjettAar }) => {
  if (tilskuddOverview.ordningerForAar.length === 0) {
    return null;
  }

  const rows = tilskuddOverview.ordningerForAar.map((tilskudd) => {
    const aktorNavn = getAktorName(tilskudd);
    // TODO
    // if (!tilskudd.tillattUtbetaling) {
    //   return (
    //     <UtenUtbetalingerRow
    //       key={`Tilskudd-row-${tilskudd.ordningId}`}
    //       tilskudd={tilskudd}
    //       aktorName={aktorNavn}
    //     />
    //   );
    // }

    const total = tilskudd.utbetalt - tilskudd.innbetalt;
    const altUbetalt = tilskudd.isGodkjent && total === tilskudd.innvilgetBelop;

    return altUbetalt ? (
      <AltUtbetaltRow key={`Tilskudd-row-${tilskudd.ordningId}`} tilskudd={tilskudd} aktorName={aktorNavn} budsjettAar={budsjettAar} />
    ) : (
      <DelvisUtbetaltRow key={`Tilskudd-row-${tilskudd.ordningId}`} tilskudd={tilskudd} aktorName={aktorNavn} budsjettAar={budsjettAar} />
    );
  });

  const title = (
    <DetailedListRow inline>
      <DetailedListCell size="80" bold left>
        {tilskuddOverview.aar}
      </DetailedListCell>
      <DetailedListCell left>Tilskudd og prosjektmidler</DetailedListCell>
    </DetailedListRow>
  );

  const showTilskuddsDetails = tilskuddOverview.ordningerForAar.every((tilskudd) => tilskudd.isGodkjent);

  return (
    <Wrapper>
      <DetailedList title={title}>{rows}</DetailedList>
      <Tilskuddoppsummering tilskuddsOverview={tilskuddOverview} showTilskuddDetails={showTilskuddsDetails} />
    </Wrapper>
  );
};
