import { useNavigate } from "react-router-dom";
import { Dropdown, DropdownItem } from "@utdanningsdirektoratet/dropdown";
import { LinkButton } from "@utdanningsdirektoratet/button";
import { getCurrentUser, useCurrentOrganization } from "../utils/globalFunctions";
import routes from "../routes";
import { Organization } from "../ApiClients";

export const AktorSelector = () => {
  const navigate = useNavigate();
  const currentOrganization = useCurrentOrganization();
  const user = getCurrentUser();

  if (user && user.roller.length > 0) {
    const items = [
      <DropdownItem closeOnClick>
        {({ className }) => (
          <a href={routes.selectAktor} className={className}>
            Bytt aktør
          </a>
        )}
      </DropdownItem>,
    ];

    return <Dropdown lists={[[], items]}>{user.roller[0]}</Dropdown>;
  }

  if (!user?.organizations || user.organizations.length <= 1) {
    return null;
  }

  if (user && user.organizations.length > 10) {
    return <LinkButton href={routes.selectAktor}>Bytt aktør</LinkButton>;
  }

  const selectOrganization = (organization: Organization) => {
    navigate(routes.dashboard(organization.number, "Oversikt"));
  };

  const items = user.organizations.map((o) => (
    <DropdownItem selected={o === currentOrganization} onClick={() => selectOrganization(o)} closeOnClick>
      {o.name}
    </DropdownItem>
  ));

  return <Dropdown lists={[items]}>Bytt aktør</Dropdown>;
};
