import React from "react";
import styled from "styled-components";
import { InpageMessage } from "@utdanningsdirektoratet/inpagemessage";
import { usePageViewTrackingInfoPage } from "../utils/analyticsUtils";

const Centered = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  margin-bottom: 5vh;
  list-style-position: inside;
`;

const InpageMessageNarrow = styled(InpageMessage)`
  width: 70vw;
  max-width: 1024px;
  b {
    display: inline-block;
    margin-bottom: 5px;
  }
`;

export const InfoPage: React.FC<{ analyticsMessage?:string, children: React.ReactNode }> = ( { analyticsMessage, children }) => {
  usePageViewTrackingInfoPage(analyticsMessage)
  return (
    <Centered>
      <InpageMessageNarrow message={children} type="info" color="blue" />
    </Centered>
  );
};
