import "./index.less";
import { createRoot } from "react-dom/client";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import App from "./App";
import StateProvider from "./StateProvider";
import reducer, { initialState } from "./reducers";
import { getConfig } from "./utils/globalFunctions";

Sentry.init({
  dsn: getConfig().sentryId ?? undefined,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0,
  release: `skoleportal@${process.env.COMMIT_HASH}`,
});

const container = document.getElementById("app");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary>
    <StateProvider reducer={reducer} initState={initialState}>
      <App />
    </StateProvider>
  </Sentry.ErrorBoundary>,
);
