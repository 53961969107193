import React from "react";
import styled from "styled-components";
import { ColumnDefinitionType } from "./commonTypes";
import SimpleTableHeader from "./SimpleTableHeader";
import SimpleTableRows from "./SimpleTableRow";

type SimpleTableProps<T> = {
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T>>;
  className?: string;
};

const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
  border-spacing: 0 0.25rem;
`;

export const SimpleTable = <T,>({ data, columns, className }: SimpleTableProps<T>): React.ReactElement | null => {
  if (data.length === 0) return null;
  return (
    <Table className={className}>
      <SimpleTableHeader columns={columns} />
      <SimpleTableRows data={data} columns={columns} />
    </Table>
  );
};

export default SimpleTable;
