import React from "react";
import styled from "styled-components";
import { SatsgruppeModel, SatsType } from "../../../../../ApiClients";

const SatsgruppeTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
  th {
    font-weight: normal;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  tbody tr {
    background-color: #eaeaea;
    border-bottom: 1px solid #d1d1d1;
    &:last-child {
      border-bottom: none;
    }
    td {
      padding: 0.75rem 0.5rem;
    }
  }
`;

const Th = styled.th<{ width: string; right?: boolean }>`
  width: ${(p) => p.width};
  text-align: ${(p) => (p.right ? "right" : "left")};
`;

const Td = styled.td<{ right?: boolean }>`
  text-align: ${(p) => (p.right ? "right" : "left")};
`;

const formatSats = (sats: SatsType) => {
  if (sats === SatsType.KunstMediaMusikk) return "Kunst-, medie- og musikkutdanning";
  if (sats === SatsType.Bibel) return "Bibelstudium m.m.";
  if (sats === SatsType.UtoverVg) return "Utover videregående opplæring";
  return sats.toString();
};

export const Satsgruppe: React.FC<{ satsgruppe: SatsgruppeModel }> = ({ satsgruppe }) => {
  if (satsgruppe?.kurs == null) return null;
  return (
    <div>
      <b>
        {satsgruppe.total} {satsgruppe.total > 1 ? "plasser" : "plass"}
      </b>
      <SatsgruppeTable>
        <thead>
          <tr>
            <Th width="35%">Kursnavn</Th>
            <Th width="8%" right>
              Satsandel
            </Th>
            <Th width="30%">Kurstilbud</Th>
            <Th width="8%" right>
              Nivå
            </Th>
          </tr>
        </thead>
        <tbody>
          {satsgruppe.kurs.map((k) => (
            <tr key={k.id}>
              <Td>{k.title}</Td>
              <Td right>{k.satsandel * 100} %</Td>
              <Td>{formatSats(k.sats)}</Td>
              <Td right>{k.level}</Td>
            </tr>
          ))}
        </tbody>
      </SatsgruppeTable>
    </div>
  );
};
