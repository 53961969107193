export const formatCurrency = (belop: number, fractionDigits = 2) => {
  return new Intl.NumberFormat("nb-NO", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(belop);
};

export const parseNorwegianFloat = (text: string) => parseFloat(text.replace("\u00a0", "").replace(",", "."));

export const convertDecimalToPercentInString = (decimalString: string) => {
  const decimalNumber = parseFloat(decimalString.replace(" ", "").replace(",", "."));
  const options = {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  };

  const percentString = `${Number(decimalNumber * 100).toLocaleString("nb", options)}%`;
  return percentString;
};
