import React from "react";
import styled from "styled-components";
import { mediaqueries } from "@utdanningsdirektoratet/styles";

type SectionProps = {
  children: React.ReactNode;
  className?: string;
};

const StyledSection = styled.section`
  padding: 2rem;
  @media ${mediaqueries.small} {
    padding: 1rem;
  }
`;

const Section: React.FC<SectionProps> = ({ children, className }) => {
  return <StyledSection className={className}>{children}</StyledSection>;
};

export default Section;
