import React from "react";
import { Placeholder } from "@utdanningsdirektoratet/placeholder";
import styled from "styled-components";
import { colors } from "@utdanningsdirektoratet/styles";
import { Link as UdirLink } from "@utdanningsdirektoratet/link";
import { Link, useParams } from "react-router-dom";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { TilskuddForAar } from "./TilskuddForAar";
import { TilskuddClient, TilskuddsOverview } from "../../../../ApiClients";
import { OrgNrParamType, useCurrentOrganization } from "../../../../utils/globalFunctions";
import routes from "../../../../routes";
import { PagePanel } from "../../../../layout/PagePanels";
import { PaddedHeading1 } from "../../../../components/Headings";

const PlaceholderWrapper = styled.div`
  width: 100%;
  max-width: 100rem;
`;

const ErrorBox = styled.div`
  width: 100%;
  padding: 3rem 2rem;
  background-color: ${colors.peach5};
`;

const Wrapper = styled.div`
  width: min(100rem, 100%);
  margin-top: 2rem;
`;

const FillWidthWrapper = styled.div`
  margin: 2rem auto;
  max-width: 80rem;
  width: min(50rem, 100%);
`;

export const TilskuddOverview: React.FC<{ disabled?: boolean }> = ({ disabled = false }) => {
  const [overview, setOverview] = React.useState<Array<TilskuddsOverview>>();
  const [isLoading, setIsloading] = React.useState(true);
  const [cannotFetchTilskudd, setCannotFetchTilskudd] = React.useState(false);
  const currentOrganization = useCurrentOrganization();
  const { orgnr } = useParams<OrgNrParamType>();

  React.useEffect(() => {
    const getOverview = async () => {
      if (!currentOrganization?.udirAktorId) return;
      setIsloading(true);
      try {
        const client = new TilskuddClient();
        const result = await client.getOverview(currentOrganization.udirAktorId);
        setOverview(result.tilskuddsOverview);

        if (result.errorMessage) {
          newNotification("error", "Noe gikk galt", result.errorMessage);
        }

        setCannotFetchTilskudd(false);
      } catch (e) {
        setCannotFetchTilskudd(true);
      } finally {
        setIsloading(false);
      }
    };
    getOverview();
  }, [currentOrganization?.udirAktorId]);

  if (disabled) {
    return (
      <PagePanel>
        <FillWidthWrapper>
          <PaddedHeading1>Tilskudd</PaddedHeading1>
          <div>Det er ingen tilskuddsdata registrert på denne aktøren</div>
        </FillWidthWrapper>
      </PagePanel>
    );
  }

  if (cannotFetchTilskudd) {
    return (
      <PagePanel backgroundColor={colors.lightgrey}>
        <PaddedHeading1>Tilskudd</PaddedHeading1>
        <ErrorBox>Kunne ikke hente tilskuddsdata</ErrorBox>
      </PagePanel>
    );
  }

  if (isLoading) {
    return (
      <PagePanel backgroundColor={colors.lightgrey}>
        <PlaceholderWrapper>
          <Placeholder height="3" />
          <Placeholder height="3" />
          <Placeholder height="3" />
          <Placeholder height="3" />
          <Placeholder height="3" />
          <Placeholder height="3" />
        </PlaceholderWrapper>
      </PagePanel>
    );
  }

  return (
    <PagePanel backgroundColor={colors.lightgrey}>
      <Wrapper>
        <PaddedHeading1>Tilskudd</PaddedHeading1>
        {overview?.map((tilskuddOverview) => (
          <TilskuddForAar key={tilskuddOverview.aar} tilskuddOverview={tilskuddOverview} budsjettAar={+tilskuddOverview.aar} />
        ))}
        <UdirLink iconRight="angle right" text="Se alle tilskudd og prosjektmidler">
          {(props) => <Link {...props} to={routes.tilskudd(orgnr!)} />}
        </UdirLink>
      </Wrapper>
    </PagePanel>
  );
};
