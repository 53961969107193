import styled from "styled-components";
import { Heading1, Heading2 } from "@utdanningsdirektoratet/styles";

export const PaddedHeading2 = styled(Heading2)`
  padding-bottom: 1rem;
`;

export const PaddedHeading1 = styled(Heading1)`
  padding-bottom: 2rem;
`;
