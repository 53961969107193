import React from "react";
import styled from "styled-components";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { LokaleModel } from "../../../../../ApiClients";
import Section from "../../../../../components/Section";
import { PaddedHeading2 } from "../../../../../components/Headings";

type LokaleProps = {
  lokale?: LokaleModel | null;
};

const Comment = styled.div`
  white-space: pre-line;
  padding-top: 1rem;
`;

const Lokale: React.FC<LokaleProps> = ({ lokale }) => {
  if (!lokale) return null;
  const kapasitet = lokale.kapasitet ? `${lokale.kapasitet} elever` : "Kapasitet er ikke satt";

  return (
    <Section>
      <PaddedHeading2>Skolens lokaler</PaddedHeading2>
      <div>
        <span className="u--bold">{kapasitet}</span>
        <span>
          &mdash; {lokale.postadresse}, {lokale.postnummer} {lokale.poststed}
        </span>
      </div>
      {(lokale.fomDatoUtc || lokale.tomDatoUtc) && (
        <div className="u--paddingTop1">
          {lokale.fomDatoUtc && `Vedtatt ${formatDate(lokale.fomDatoUtc, "dd.MM.yyyy")}. `}
          {lokale.tomDatoUtc && `Utløper ${formatDate(lokale.tomDatoUtc, "dd.MM.yyyy")}`}
        </div>
      )}
      <Comment>{lokale.kommentar}</Comment>
    </Section>
  );
};

export default Lokale;
