import React from "react";
import styled from "styled-components";

type ElevplassgruppeProps = {
  title: string;
  total: number;
  children: React.ReactNode;
};

const Wrapper = styled.div`
  padding: 1.5rem 0;
`;

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
`;

const Elevplassgruppe: React.FC<ElevplassgruppeProps> = ({ title, total, children }) => {
  return (
    <Wrapper>
      <Total>
        <span>{title}</span>
        <span>{total}</span>
      </Total>
      <div>{children}</div>
    </Wrapper>
  );
};

export default Elevplassgruppe;
