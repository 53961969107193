import React from "react";
import styled from "styled-components";
import { KeyValue, Key, Value } from "@utdanningsdirektoratet/keyvalue";
import { formatCurrency } from "../../../../../utils/numberUtils";
import { TilskuddsDetaljer } from "../../../../../ApiClients";

const Wrapper = styled.div<{ splitColumnView?: boolean }>`
  max-width: 20rem;
  margin-right: 0;
  margin-left: auto;
  ${(props) =>
    props.splitColumnView &&
    `
      max-width: 40rem;
      display: grid;
      grid-column-gap: 2rem;
      grid-template-columns: 1fr 1fr;
      & > *:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
  `}
`;

type BetalingsoppsummeringProps = {
  tilskuddDetaljer: TilskuddsDetaljer;
  terminplanGodkjent: boolean;
};

export const Betalingsoppsummering: React.FC<BetalingsoppsummeringProps> = ({ tilskuddDetaljer, terminplanGodkjent }) => {
  const inProgressText = "Udir beregner total";
  const hasUtbetalinger =
    !(tilskuddDetaljer.sumUtbetalingerUtbetalt === 0 && tilskuddDetaljer.sumUtbetalingerRest === 0) &&
    tilskuddDetaljer.sumUtbetalingerUtbetalt !== tilskuddDetaljer.total;
  const hasInnbetalinger =
    !(tilskuddDetaljer.sumInnbetalingerInnbetalt === 0 && tilskuddDetaljer.sumInnbetalingerRest === 0) &&
    tilskuddDetaljer.sumInnbetalingerInnbetalt !== tilskuddDetaljer.total;

  const hasGjenstandeUtbetalingEllerInnbetaling = tilskuddDetaljer.sumUtbetalingerRest !== 0 || tilskuddDetaljer.sumInnbetalingerRest !== 0;

  const totaltColor = tilskuddDetaljer.total < 0 ? "darkred" : "";
  const sumInnbetalingerRestColor = tilskuddDetaljer.sumInnbetalingerRest < 0 ? "darkred" : "";

  const totaltKey = terminplanGodkjent ? "Totalt" : "Foreløpig total";

  const totalValue = terminplanGodkjent
    ? tilskuddDetaljer.total
    : tilskuddDetaljer.sumUtbetalingerUtbetalt - tilskuddDetaljer.sumInnbetalingerInnbetalt;

  return (
    <Wrapper splitColumnView={hasUtbetalinger && hasInnbetalinger}>
      <KeyValue bold>
        <Key>{totaltKey}</Key>
        <Value color={totaltColor}>{terminplanGodkjent ? formatCurrency(totalValue) : inProgressText}</Value>
      </KeyValue>
      {hasUtbetalinger || !terminplanGodkjent ? (
        <div>
          <KeyValue>
            <Key>Utbetalt</Key>
            <Value>{formatCurrency(tilskuddDetaljer.sumUtbetalingerUtbetalt)}</Value>
          </KeyValue>
          {hasGjenstandeUtbetalingEllerInnbetaling || !terminplanGodkjent ? (
            <KeyValue>
              <Key>Til utbetaling</Key>
              <Value>{terminplanGodkjent ? formatCurrency(tilskuddDetaljer.sumUtbetalingerRest) : inProgressText}</Value>
            </KeyValue>
          ) : null}
        </div>
      ) : null}
      {hasInnbetalinger ? (
        <div>
          <KeyValue>
            <Key>Innbetalt</Key>
            <Value color="darkred">{formatCurrency(tilskuddDetaljer.sumInnbetalingerInnbetalt)}</Value>
          </KeyValue>
          {hasGjenstandeUtbetalingEllerInnbetaling ? (
            <KeyValue>
              <Key>Skal innbetales</Key>
              <Value color={sumInnbetalingerRestColor}>{formatCurrency(tilskuddDetaljer.sumInnbetalingerRest)}</Value>
            </KeyValue>
          ) : null}
        </div>
      ) : null}
    </Wrapper>
  );
};
