import styled from "styled-components";
import { ColumnDefinitionType } from "./commonTypes";

type SimpleTableHeaderProps<T> = {
  columns: Array<ColumnDefinitionType<T>>;
};

const Th = styled.th<{ textAlign?: string; fontWeight: string }>`
  text-align: left;
  font-size: 0.875rem;
  font-weight: normal;
  padding: 0.25rem 1rem;
  text-align: ${(props) => props.align};
  font-weight: ${(props) => props.fontWeight};
`;

const SimpleTableHeader = <T,>({ columns }: SimpleTableHeaderProps<T>): JSX.Element => {
  const headers = columns
    .filter((column) => !column.hidden)
    .map((column, index) => {
      return (
        <Th key={`headCell-${index}`} textAlign={column.alignRight ? "right" : ""} fontWeight={column.bold ? "bold" : ""}>
          <div className={column.alignRight ? "u--alignRight" : ""}>{column.header}</div>
        </Th>
      );
    });

  return (
    <thead>
      <tr>{headers}</tr>
    </thead>
  );
};

export default SimpleTableHeader;
