const orgNrMask = {
  regex: /^([F,U]?\d{3})(\d{3})(\d{3}).*/,
  resultPattern: "$1 $2 $3",
};
const kontoNrMask = {
  regex: /^(\d{4})(\d{2})(\d{5}).*/,
  resultPattern: "$1.$2.$3",
};

const mobilTlfMask = {
  regex: /^(\d{3})(\d{2})(\d{3}).*/,
  resultPattern: "$1 $2 $3",
};
const fastTlfMask = {
  regex: /^(\d{2})(\d{2})(\d{2})(\d{2}).*/,
  resultPattern: "$1 $2 $3 $4",
};

const mask = (regex: RegExp, result: string, value: string) => {
  return value.replace(regex, result);
};

const isMobilTlf = (tlfNr: string) => {
  return ["4", "8", "9"].indexOf(tlfNr.charAt(0)) >= 0 && tlfNr.length === 8;
};

const isFastTlf = (tlfNr: string) => {
  return ["2", "3", "5", "6", "7"].indexOf(tlfNr.charAt(0)) >= 0 && tlfNr.length === 8;
};

export const orgNrFormatter = (orgNr: string | undefined | null) => {
  if (!orgNr) return "—";
  if (!(orgNr.length === 9 || orgNr.length === 10)) return orgNr;
  return mask(orgNrMask.regex, orgNrMask.resultPattern, orgNr);
};

export const bankkontoNrFormatter = (kontoNummer: string | undefined | null) => {
  if (!kontoNummer) return "—";
  return mask(kontoNrMask.regex, kontoNrMask.resultPattern, kontoNummer);
};

export const telefonNrFormatter = (tlfNr: string | undefined | null) => {
  if (!tlfNr) return "—";
  if (isMobilTlf(tlfNr)) {
    return mask(mobilTlfMask.regex, mobilTlfMask.resultPattern, tlfNr);
  }
  if (isFastTlf(tlfNr)) {
    return mask(fastTlfMask.regex, fastTlfMask.resultPattern, tlfNr);
  }
  return tlfNr;
};
