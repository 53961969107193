import React from "react";
import { DetailedListCell, DetailedListRow } from "@utdanningsdirektoratet/detailedlist";
import { Label } from "@utdanningsdirektoratet/label";

type AvsluttetSakRowProps = {
  row: {
    date: Date;
    type: string;
    title: string | null | undefined;
    status: string;
    success: boolean;
    url: string;
  };
};

const AvsluttetSakRow: React.FC<AvsluttetSakRowProps> = ({ row }) => {
  return (
    <DetailedListRow color="grey">
      {({ className }) => {
        return (
          <a className={className} href={row.url} target="_blank" rel="noopener noreferrer">
            <DetailedListCell bold left size="15%">
              {row.type}
            </DetailedListCell>
            <DetailedListCell bold size="25%">
              <Label text={row.status} color={row.success ? "green" : "red"}></Label>
            </DetailedListCell>
            <DetailedListCell bold left size="60%">
              {row.title}
            </DetailedListCell>
          </a>
        );
      }}
    </DetailedListRow>
  );
};

export default AvsluttetSakRow;
