import groupBy from "lodash/groupBy";
import { InnsamlingApiResponse, PublisertInnsamlingStatus, SoknadMetadataViewModel, StatusEnum, TilsynViewModel } from "../ApiClients";
import { getConfig } from "./globalFunctions";
import { sortDate } from "./dateUtils";

const getInnsamlingStatus = (status: PublisertInnsamlingStatus) => {
  if (status === PublisertInnsamlingStatus.Avsluttet) return "Avsluttet";
  if (status === PublisertInnsamlingStatus.InnsendtUtenGodkjenning) return "Innsendt uten godkjenning";
  if (status === PublisertInnsamlingStatus.Ledergodkjent) return "Ledergodkjent";
  if (status === PublisertInnsamlingStatus.Publisert) return "Publisert";
  if (status === PublisertInnsamlingStatus.Revisorgodkjent) return "Revisorgodkjent";
  return "Ukjent";
};

const getSoknadsStatus = (soknad: SoknadMetadataViewModel) => {
  if (soknad.lastStatus.status === StatusEnum.FerdigBehandlet) return "Ferdig behandlet";
  if (soknad.lastStatus.status === StatusEnum.Withdrawn) return "Trukket";
  if (soknad.lastStatus.status === StatusEnum.Imported) {
    if (soknad.allStatus.some((s) => s.status === StatusEnum.Withdrawn)) {
      return "Trukket";
    }
  }
  return "Ukjent";
};
export const mergeAvsluttedeSaker = (
  avsluttedeSoknader: SoknadMetadataViewModel[],
  avsluttedeInnsamlinger: InnsamlingApiResponse[],
  avsluttedeTilsyn: TilsynViewModel[],
) => {
  const listOfAllAvsluttede = avsluttedeSoknader
    .map((i) => ({
      date: new Date(i.lastModified),
      type: "Søknad",
      title: i.formTitle,
      status: getSoknadsStatus(i),
      success: i.lastStatus.status === StatusEnum.FerdigBehandlet,
      url: `${getConfig().skolesoknadUrl}/${i.aktorId}/soknad/${i.id}`,
    }))
    .concat(
      avsluttedeInnsamlinger.map((i) => ({
        date: new Date(i.frist),
        type: "Innsamling",
        title: i.tittel,
        status: getInnsamlingStatus(i.status),
        success: true,
        url: `${getConfig().innsamlingUrl}/innsamling/${i.externalId}`,
      })),
    )
    .concat(
      avsluttedeTilsyn.map((t) => ({
        date: new Date(t.endDateUtc!),
        type: "Tilsyn",
        title: t.name,
        status: "Avsluttet",
        success: true,
        url: `${getConfig().tilsynUrl}/Home/Authenticate?returnUrl=/tilsyn/${t.externalId}`, // tving innlogging i tilsyn
      })),
    )
    .sort((a, b) => sortDate(a.date, b.date, "dsc"));

  return listOfAllAvsluttede;
};

export const mergeAndGroupAvsluttedeSaker = (
  avsluttedeSoknader: SoknadMetadataViewModel[],
  avsluttedeInnsamlinger: InnsamlingApiResponse[],
  avsluttedeTilsyn: TilsynViewModel[],
) => {
  const listOfAllAvsluttede = mergeAvsluttedeSaker(avsluttedeSoknader, avsluttedeInnsamlinger, avsluttedeTilsyn);

  const avsluttedeGroupedByYear = groupBy(listOfAllAvsluttede, (v) => v.date.getFullYear());
  return avsluttedeGroupedByYear;
};
