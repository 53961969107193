import * as React from "react";
import { ApiException } from "../ApiClients";

export const ERROR_SET = "SET_ERROR";
export const ALTINN_SET = "SET_ALTINN";

export const initialState: ReducerStateProps = {
  error: null,
  altinn: null,
};

export type ReducerStateProps = {
  error: ApiException | null;
  altinn: {
    hasRead: boolean;
    hasReadWrite: boolean;
    orgNr: string;
  } | null;
};

type SetErrorType = {
  type: typeof ERROR_SET;
  payload: ApiException;
};

type SetAltinnType = {
  type: typeof ALTINN_SET;
  payload: {
    hasRead: boolean;
    hasReadWrite: boolean;
    orgNr: string;
  } | null;
};

export type ActionTypeProps = SetErrorType | SetAltinnType;

const reducer: React.Reducer<ReducerStateProps, ActionTypeProps> = (state = initialState, action) => {
  switch (action.type) {
    case ERROR_SET:
      return { ...state, error: action.payload };
    case ALTINN_SET:
      return { ...state, altinn: action.payload };
    default:
      return state;
  }
};

export default reducer;
