import { LinkButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { colors } from "@utdanningsdirektoratet/styles";
import React from "react";
import styled from "styled-components";
import { AltinnInfoLink } from "./Dashboard/components/SharedComponents";
import { usePageViewTrackingWithoutAccessInfo } from "../utils/analyticsUtils";

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem 5rem;
  > div {
    padding: 1rem;
    background-color: ${colors.eggshell4};
    text-align: center;
    max-width: 800px;
  }
`;

const IconDiv = styled.div`
  margin: 1rem 0;
`;

const ButtonDiv = styled.div`
  margin: 2rem 1rem;
`;

const InfoTitle = styled.p`
  font-size: large;
  font-weight: bold;
`;

export const LogonPage: React.FC = () => {
  usePageViewTrackingWithoutAccessInfo()
  return (
    <CenteredWrapper>
      <div>
        <IconDiv>
          <Icon type="large" icon="circle info" />
        </IconDiv>
        <InfoTitle>Tavla benytter Altinn-roller og tilganger</InfoTitle>
        <p>
          For å logge inn på Tavla må du enten ha en rolle eller tilgang i Altinn. Daglig leder og de med administratorrolle i Altinn har automatisk
          tilgang. Ansatte må få tildelt <b>Tavla - Lesetilgang</b> eller <b>Tavla - Skrive- og lesetilgang</b> for å logge inn.
        </p>
        <p>Revisorer må også knyttes til organisasjonen gjennom Altinn-roller.</p>
        <AltinnInfoLink>Les mer om tilgangsstyring i Altinn</AltinnInfoLink>
        <ButtonDiv>
          <LinkButton primary href="/home/authenticate">
            Logg inn
          </LinkButton>
        </ButtonDiv>
      </div>
    </CenteredWrapper>
  );
};
