import React from "react";
import styled from "styled-components";
import useSWRImmutable from "swr/immutable";
import { Heading1 } from "@utdanningsdirektoratet/styles";
import { Label } from "@utdanningsdirektoratet/label";
import { Icon } from "@utdanningsdirektoratet/icon";
import { useCurrentOrganization } from "../../../utils/globalFunctions";
import { AktorClient, AktorStatus } from "../../../ApiClients";

const Wrapper = styled.div`
  padding: 3rem 2rem;
  background-color: var(--steelblue4);
  border-bottom: 1px solid var(--steelblue5);
`;

const HeadingAndLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const AktorHeaderBox: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const currentOrganization = useCurrentOrganization();
  const { data: aktor } = useSWRImmutable(!currentOrganization?.udirAktorId ? null : `getAktor-${currentOrganization.udirAktorId}`, () =>
    new AktorClient().get(currentOrganization!.udirAktorId!),
  );

  return (
    <Wrapper>
      <HeadingAndLabel>
        <Heading1>{aktor?.navn}</Heading1>
        {aktor?.isActive === false && (
          <Label
            text={
              <>
                <Icon icon="warning" color="white" placement="before" />
                {aktor?.status === AktorStatus.Ukjent ? "Denne aktøren har ukjent driftstatus" : "Denne aktøren er ikke i drift"}
              </>
            }
          />
        )}
      </HeadingAndLabel>
      {children}
    </Wrapper>
  );
};
