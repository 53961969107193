import React from "react";
import { ColumnDefinitionType } from "../../SimpleTable/commonTypes";
import { ZebraSimpleTable } from "../../SimpleTable/StyledSimpleTable";
import { convertDecimalToPercentInString, formatCurrency, parseNorwegianFloat } from "../../../../../utils/numberUtils";
import { TilskuddsberegningDetails, VisningsType } from "../../../../../ApiClients";

type Props = {
  tilskuddsberegning?: TilskuddsberegningDetails;
};

export const BeregningsTable: React.FC<Props> = ({ tilskuddsberegning }) => {
  const columns: ColumnDefinitionType<string[]>[] = tilskuddsberegning
    ? tilskuddsberegning.beregningsKolonner.map((s, index) => ({
        header: s.kolonneNavn,
        key: index,
        alignRight: s.visningsType !== VisningsType.Tekst,
        sum: s.sum ? formatCurrency(s.sum) : undefined,
        data: (f: string) => {
          if (s.visningsType === VisningsType.Desimaltall) {
            return f === "" || f === undefined || f === null ? "-" : formatCurrency(parseNorwegianFloat(f));
          }
          if (s.visningsType === VisningsType.Prosent) {
            return f === "" ? "-" : convertDecimalToPercentInString(f);
          }
          return f;
        },
      }))
    : [];

  return <ZebraSimpleTable data={tilskuddsberegning?.data ?? []} columns={columns} />;
};
