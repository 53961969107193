import React from "react";
import { Section } from "@utdanningsdirektoratet/layout";
import { Heading2 } from "@utdanningsdirektoratet/styles";
import styled from "styled-components";
import { FriskoleGrunnskoleModel } from "../../../../../ApiClients";
import { TitleSubtitle, TotalsRow } from "../../SharedComponents";

interface Props {
  elevtall: FriskoleGrunnskoleModel | undefined | null;
}

function toTrinn(trinn: number[] | undefined | null) {
  if (trinn == null) return "Alle";
  if (trinn.length === 0) return `Trinn ${trinn[0]}.`;
  return `${Math.min(...trinn)} - ${Math.max(...trinn)} trinn`;
}

const Spacer = styled.div`
  margin-top: 1rem;
`;

const Trinn = styled.div`
  display: flex;
  width: 258px;
  justify-content: space-between;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
`;

const TrinnRad: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => {
  return (
    <Trinn>
      <span>{title}</span>
      <span>{children}</span>
    </Trinn>
  );
};

export const ElevtallGrunnskole: React.FC<Props> = ({ elevtall }) => {
  if (elevtall == null) return null;
  const total = (elevtall.elevplassgrupper ?? []).map((g) => g.total).reduce((partialSum, a) => partialSum + a, 0);

  return (
    <Section>
      <Heading2>Elevplasser grunnskole</Heading2>
      <TotalsRow>
        <TitleSubtitle title="Total" subtitle={total} />
        <TitleSubtitle title="Ordinære" subtitle={elevtall.ordinar} />
        <TitleSubtitle title="Særskilt tilrettelagte" subtitle={elevtall.spesial} />
      </TotalsRow>
      <Spacer>
        {elevtall.elevplassgrupper?.map((g) => (
          <TrinnRad key={g.id} title={toTrinn(g.trinns)}>
            {g.total}
          </TrinnRad>
        ))}
      </Spacer>
    </Section>
  );
};
