import React from "react";
import { Footer, MainPanel, MenuPanel } from "@utdanningsdirektoratet/layout";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import {
  ApplicationInsightsCookieVendor,
  CookieBanner,
  MatomoCookieVendor,
  NecessaryCookie,
  SentryCookieVendor,
  StatisticsCookie,
  UidpCookieVendor,
} from "@utdanningsdirektoratet/cookiebanner";
import { onKeyDown } from "@utdanningsdirektoratet/utils/uu";
import { AktorSelector } from "./AktorSelector";
import { UserSignoutDropdown } from "./UserSignoutDropdown";
import { useCurrentOrganization } from "../utils/globalFunctions";
import routes from "../routes";

const StyledMainPanel = styled(MainPanel)`
  padding: 0;
`;

const ItemSeperator = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 3rem;
  align-items: center;
`;

const AppContainer: React.FC = () => {
  const currentOrganization = useCurrentOrganization();
  const [cookieState, setCookieState] = React.useState(false);

  const items = [
    <a href={routes.udir.om} target="_blank" rel="noopener noreferrer">
      Om direktoratet
    </a>,
    <a href={routes.udir.kontakt} target="_blank" rel="noopener noreferrer">
      Kontaktinformasjon
    </a>,
    <a href={routes.personvern} rel="noopener noreferrer">
      Personvernerklæring
    </a>,
    <a href="https://uustatus.no/nb/erklaringer/publisert/39d97205-92e5-448c-8c7c-51ae02ea4d90" target="_blank" rel="noopener noreferrer">
      Tilgjengelighetserklæring
    </a>,
    <div role="link" onClick={() => setCookieState(true)} onKeyDown={onKeyDown(() => setCookieState(true))} tabIndex={0}>
      Informasjonskapsler
    </div>,
  ];

  return (
    <>
      <MenuPanel title="Tavla" external>
        <ItemSeperator>
          {currentOrganization?.name}
          <AktorSelector />
          <UserSignoutDropdown />
        </ItemSeperator>
      </MenuPanel>
      <StyledMainPanel fullwidth color="white">
        <Outlet />
      </StyledMainPanel>
      <Footer items={items} udirLogo linkButton={{ href: "https://www.udir.no", title: "udir.no" }} />
      <CookieBanner
        show={cookieState}
        necessaryCookie={NecessaryCookie([UidpCookieVendor, SentryCookieVendor])}
        cookies={[StatisticsCookie([ApplicationInsightsCookieVendor, MatomoCookieVendor])]}
        onAccept={() => setCookieState(false)}
      />
    </>
  );
};

export default AppContainer;
