import { SelectFilter, Table, TextFilter } from "@utdanningsdirektoratet/table";
import React from "react";
import { useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
import { AktorClient, AktorModel } from "../../../ApiClients";
import routes from "../../../routes";

const client = new AktorClient();

export const VelgAktorSaksbehandlerTable: React.FC<{ data: AktorModel[] }> = ({ data }) => {
  const navigate = useNavigate();
  const { data: options = [] } = useSWRImmutable("aktorStatuses", async () => {
    return client.getAktorStatuses();
  });

  const aktorStatusOptions = [{ text: "Alle", value: "" }, ...options.map((status) => ({ text: status.description, value: status.id }))];

  return (
    <Table
      columns={[
        {
          header: "Aktør",
          key: "navn",
          sort: true,
          tooltip: false,
          filter: TextFilter,
        },
        {
          header: "Orgnummer",
          key: "orgNr",
          sort: true,
          tooltip: false,
          filter: TextFilter,
        },
        {
          header: "Driftsstatus",
          key: "status",
          sort: true,
          tooltip: false,
          data: (item) => options.find((s) => s.id === item)?.description ?? item,
          filter: (props) => <SelectFilter {...props} options={aktorStatusOptions} />,
        },
      ]}
      data={data}
      options={{
        rowOnClick: (row) => (row.isSchool ? navigate(routes.dashboard(row.orgNr, "Oversikt")) : navigate(routes.dashboard(row.orgNr, "Tilskudd"))),
      }}
    ></Table>
  );
};
