import React from "react";
import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";
import { Button } from "@utdanningsdirektoratet/button";
import { GodkjenningModel } from "../../../../ApiClients";
import Lokale from "./Privatskole/Lokale";
import ElevtallVgs from "./Privatskole/ElevtallVgs";
import Laereplan from "./Privatskole/Laereplan";
import { PaddedHeading1 } from "../../../../components/Headings";
import { ElevtallGrunnskole } from "./Privatskole/ElevtallGrunnskole";
import { Elevplasser } from "./Diverseskole/Elevplasser";
import { PagePanel } from "../../../../layout/PagePanels";

const Container = styled.div`
  background-color: ${colors.white};
  width: 100%;
`;

const NavigationWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Wrapper = styled.article`
  margin: 2rem auto;
  max-width: 80rem;
  width: 100%;
  height: 100%;
`;

const DiverseSkoleTilbud: React.FC<{ tilbud: GodkjenningModel }> = ({ tilbud }) => {
  if (!tilbud || !tilbud.diverseskole) return null;
  return (
    <Container>
      <Elevplasser satsgrupper={tilbud.diverseskole.satsgrupper} />
    </Container>
  );
};

const PrivatSkoleTilbud: React.FC<{ tilbud: GodkjenningModel }> = ({ tilbud }) => {
  if (!tilbud || !tilbud.friskole) return null;
  return (
    <Container>
      <ElevtallVgs vgs={tilbud.friskole.vgs} />
      <ElevtallGrunnskole elevtall={tilbud.friskole.grunnskole} />
      <Laereplan laereplan={tilbud.friskole.laereplan} />
      <Lokale lokale={tilbud.friskole.lokale} />
    </Container>
  );
};

type Props = {
  tilbuds: Array<GodkjenningModel> | null;
};

const getCorrectTilbud = (tilbud: GodkjenningModel): React.ReactNode | null => {
  if (!tilbud) return null;
  if (tilbud.friskole != null) return <PrivatSkoleTilbud tilbud={tilbud} />;
  if (tilbud.diverseskole != null) return <DiverseSkoleTilbud tilbud={tilbud} />;
  return null;
};

const isSelectedTilbud = (tilbud: GodkjenningModel, selectedTilbud: GodkjenningModel | undefined, currentTilbud: GodkjenningModel) => {
  if (!selectedTilbud) {
    return tilbud.validFromYear === currentTilbud.validFromYear && tilbud.validFromSemester === currentTilbud.validFromSemester;
  }
  return tilbud.validFromYear === selectedTilbud.validFromYear && tilbud.validFromSemester === selectedTilbud.validFromSemester;
};

const isGjeldendeTilbud = (tilbud: GodkjenningModel, currentTilbud: GodkjenningModel) => {
  return tilbud.validFromYear === currentTilbud.validFromYear && tilbud.validFromSemester === currentTilbud.validFromSemester;
};

const Tilbud: React.FC<Props> = ({ tilbuds }) => {
  const [selectedTilbud, setSelectedTilbud] = React.useState<GodkjenningModel>();
  if (!tilbuds || tilbuds.length === 0)
    return (
      <PagePanel>
        <Wrapper>
          <PaddedHeading1>Tilbud</PaddedHeading1>
          <div>Her vises tilbudet til skoler som er godkjent etter privatskoleloven.</div>
        </Wrapper>
      </PagePanel>
    );
  const currentTilbud = tilbuds[0];

  const selectedOrDefaultTilbud = selectedTilbud ?? currentTilbud;

  const title = isGjeldendeTilbud(selectedOrDefaultTilbud, currentTilbud)
    ? "Skolens gjeldende tilbud"
    : `Skolens tilbud - gjeldende fra ${selectedOrDefaultTilbud.validFromSemester} ${selectedOrDefaultTilbud.validFromYear}`;

  return (
    <PagePanel backgroundColor={colors.lightgrey}>
      <Wrapper>
        <PaddedHeading1>{title}</PaddedHeading1>
        {tilbuds.length > 1 && (
          <NavigationWrapper>
            {tilbuds.map((t) => (
              <Button
                key={`${t.validFromSemester}-${t.validFromYear}`}
                onClick={() => setSelectedTilbud(t)}
                active={isSelectedTilbud(t, selectedTilbud, currentTilbud)}
              >
                {isGjeldendeTilbud(t, currentTilbud) ? "Gjeldende tilbud" : `Tilbud - fra ${t.validFromSemester} ${t.validFromYear}`}
              </Button>
            ))}
          </NavigationWrapper>
        )}
        {getCorrectTilbud(selectedOrDefaultTilbud)}
      </Wrapper>
    </PagePanel>
  );
};

export default Tilbud;
