import React from "react";
import { DetailedListRow, DetailedListCell } from "@utdanningsdirektoratet/detailedlist";
import { Link, useParams } from "react-router-dom";
import routes from "../../../../routes";
import { OrdningerForAar } from "../../../../ApiClients";
import { formatCurrency } from "../../../../utils/numberUtils";
import { OrgNrParamType } from "../../../../utils/globalFunctions";

type AltUtbetaltRowProps = {
  tilskudd: OrdningerForAar;
  aktorName?: string;
  budsjettAar: number;
};

const getTotaltTilskuddDisplayValue = (tilskudd: OrdningerForAar) => {
  // if (tilskudd.isSoknadFerdigBehandlet && tilskudd.innvilgetBelop === 0) {
  //   return tilskudd.utbetalt;
  // }
  // TODO: hva er logikken her..
  if (tilskudd.innvilgetBelop === 0) {
    return formatCurrency(tilskudd.utbetalt);
  }

  return formatCurrency(tilskudd.innvilgetBelop);
};

export const AltUtbetaltRow: React.FC<AltUtbetaltRowProps> = ({ tilskudd, aktorName, budsjettAar }) => {
  const { orgnr } = useParams<OrgNrParamType>();
  const isUtbetaling = tilskudd.innvilgetBelop >= 0;

  const tekst = isUtbetaling ? "Hele beløpet er utbetalt" : "Hele beløpet er innbetalt";
  return (
    <DetailedListRow>
      {({ className }) => {
        return (
          <Link
            className={className}
            to={routes.grunnlag(orgnr!, budsjettAar.toString(), tilskudd.ordningId.toString(), tilskudd.isFromNewVertikal.toString())}
          >
            <DetailedListCell>
              {tilskudd.ordningNavn} {aktorName}
            </DetailedListCell>
            <DetailedListCell size="50%" right>
              {tekst}
            </DetailedListCell>
            <DetailedListCell size="140px" bold right className={isUtbetaling ? "" : "u--darkred"}>
              {getTotaltTilskuddDisplayValue(tilskudd)}
            </DetailedListCell>
          </Link>
        );
      }}
    </DetailedListRow>
  );
};
