import React from "react";
import { KeyValue, Key, Value } from "@utdanningsdirektoratet/keyvalue";
import styled from "styled-components";
import { formatCurrency } from "../../../../utils/numberUtils";
import { TilskuddsOverview } from "../../../../ApiClients";

const Wrapper = styled.div<{ splitColumnView?: boolean }>`
  max-width: 20rem;
  margin-right: 0;
  margin-left: auto;
  ${(props) =>
    props.splitColumnView &&
    `
      max-width: 40rem;
      display: grid;
      grid-column-gap: 2rem;
      grid-template-columns: 1fr 1fr;
      & > *:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
  `}
`;

type TilskuddoppsummeringProps = {
  tilskuddsOverview: TilskuddsOverview;
  showTilskuddDetails: boolean;
};

export const Tilskuddoppsummering: React.FC<TilskuddoppsummeringProps> = ({ tilskuddsOverview, showTilskuddDetails }) => {
  const hasUtbetalinger =
    !(tilskuddsOverview.sumUtbetalingerUtbetalt === 0 && tilskuddsOverview.sumUtbetalingerRest === 0) &&
    tilskuddsOverview.sumUtbetalingerUtbetalt !== tilskuddsOverview.totaltTilskudd;
  const hasInnbetalinger =
    !(tilskuddsOverview.sumInnbetalingerInnbetalt === 0 && tilskuddsOverview.sumInnbetalingerRest === 0) &&
    tilskuddsOverview.sumInnbetalingerInnbetalt !== tilskuddsOverview.totaltTilskudd;

  const hasGjenstandeUtbetalingEllerInnbetaling = tilskuddsOverview.sumUtbetalingerRest !== 0 || tilskuddsOverview.sumInnbetalingerRest !== 0;

  const inProgressText = "Udir beregner total";
  const totaltColor = tilskuddsOverview.totaltTilskudd < 0 ? "darkred" : undefined;
  const sumInnbetalingerRestColor = tilskuddsOverview.sumInnbetalingerRest < 0 ? "darkred" : undefined;
  const formattedInnbetaltBelop = formatCurrency(
    tilskuddsOverview.sumInnbetalingerInnbetalt > 0 ? tilskuddsOverview.sumInnbetalingerInnbetalt * -1 : tilskuddsOverview.sumInnbetalingerInnbetalt,
  );

  return (
    <Wrapper splitColumnView={hasUtbetalinger && hasInnbetalinger}>
      <KeyValue>
        <Key>Totalt</Key>
        <Value color={totaltColor}>{showTilskuddDetails ? formatCurrency(tilskuddsOverview.totaltTilskudd) : inProgressText}</Value>
      </KeyValue>
      {/* Display outgoing payment numbers */}
      {(hasUtbetalinger || !showTilskuddDetails) && (
        <div>
          <KeyValue>
            <Key>Utbetalt</Key>
            <Value>{formatCurrency(tilskuddsOverview.sumUtbetalingerUtbetalt)}</Value>
          </KeyValue>
          {(hasGjenstandeUtbetalingEllerInnbetaling || !showTilskuddDetails) && (
            <KeyValue>
              <Key>Til utbetaling</Key>
              <Value>{showTilskuddDetails ? formatCurrency(tilskuddsOverview.sumUtbetalingerRest) : inProgressText}</Value>
            </KeyValue>
          )}
        </div>
      )}
      {/* Display incoming payment numbers */}
      {hasInnbetalinger && (
        <div>
          <KeyValue>
            <Key>Innbetalt</Key>
            <Value color="darkred">{formattedInnbetaltBelop}</Value>
          </KeyValue>
          {hasGjenstandeUtbetalingEllerInnbetaling && (
            <KeyValue>
              <Key>Skal innbetales</Key>
              <Value color={sumInnbetalingerRestColor}>{formatCurrency(tilskuddsOverview.sumInnbetalingerRest)}</Value>
            </KeyValue>
          )}
        </div>
      )}
    </Wrapper>
  );
};
