import React, { useEffect } from "react";
import styled from "styled-components";
import { colors, Heading2 } from "@utdanningsdirektoratet/styles";
import { Link as UdirLink } from "@utdanningsdirektoratet/link";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import { Placeholder } from "@utdanningsdirektoratet/placeholder";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { AktorHeaderBox } from "../AktorHeaderBox";
import { PagePanel } from "../../../../layout/PagePanels";
import routes from "../../../../routes";
import { OrgNrParamType, useCurrentOrganization } from "../../../../utils/globalFunctions";
import { TilskuddClient } from "../../../../ApiClients";
import { TilskuddForAar } from "./TilskuddForAar";

const Container = styled.div`
  margin-bottom: 2rem;
  width: min(50rem, 100%);
`;

const PlaceholderWrapper = styled.div`
  width: 100%;
  max-width: 100rem;
`;

const ErrorBox = styled.div`
  width: 100%;
  padding: 3rem 2rem;
  background-color: ${colors.peach5};
`;

export const AllTilskuddOverview: React.FC = () => {
  const currentOrganization = useCurrentOrganization();
  const {
    data: tilskudds,
    isLoading,
    error,
  } = useSWR(
    !currentOrganization?.udirAktorId ? null : `tilskudd/all/${currentOrganization?.udirAktorId}`,
    () => new TilskuddClient().getAllOverview(currentOrganization!.udirAktorId!),
    {
      errorRetryCount: 1,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const { orgnr } = useParams<OrgNrParamType>();

  useEffect(() => {
    if (tilskudds?.errorMessage) {
      newNotification("error", "Noe gikk galt", tilskudds?.errorMessage);
    }
  }, [tilskudds]);

  if (error) {
    return <ErrorBox>Kunne ikke hente tilskuddsdata</ErrorBox>;
  }

  return (
    <>
      <AktorHeaderBox>
        <UdirLink iconLeft="angle left" text="Tilbake">
          {(props) => <Link {...props} to={routes.dashboard(orgnr!, "Tilskudd")} />}
        </UdirLink>
      </AktorHeaderBox>
      <PagePanel backgroundColor={colors.lightgrey}>
        <Container>
          <Heading2 className="u--marginTop2 u--marginBottom2">Tilskudd og prosjektmidler</Heading2>
          {isLoading && (
            <PlaceholderWrapper>
              <Placeholder height="3" />
              <Placeholder height="3" />
              <Placeholder height="3" />
              <Placeholder height="3" />
              <Placeholder height="3" />
              <Placeholder height="3" />
            </PlaceholderWrapper>
          )}
          {tilskudds?.tilskuddsOverview
            ?.sort((t1, t2) => (t1.aar < t2.aar ? 1 : -1))
            .map((tilskuddOverview) => (
              <TilskuddForAar key={tilskuddOverview.aar} tilskuddOverview={tilskuddOverview} budsjettAar={+tilskuddOverview.aar} />
            ))}
        </Container>
      </PagePanel>
    </>
  );
};
