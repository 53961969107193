import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";
import SimpleTable from "./SimpleTable";

export const ZebraSimpleTable = styled(SimpleTable)`
  border-spacing: 0 0;
  padding-top: 0.75rem;

  th {
    border-bottom: 0.125rem solid ${colors.bordergrey};
    font-size: 1rem;
    font-weight: bold;
  }

  tr:nth-child(odd) {
    background: white;
  }

  tr:nth-child(even) {
    background: #eaeaea;
  }
` as typeof SimpleTable;
