import React from "react";
import { Icon } from "@utdanningsdirektoratet/icon";
import styled from "styled-components";
import { Placeholder } from "@utdanningsdirektoratet/placeholder";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { Tilskuddsbrev as TilskuddsbrevModel, TilskuddsbrevClient, TilskuddsgrunnlagKilde } from "../../../../../ApiClients";

type TilskuddsbrevProps = {
  kildetabell: TilskuddsgrunnlagKilde;
  kildeId: number;
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
`;

const Date = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  color: #00468e;
  margin-bottom: 0.3rem;
`;

const Brev = styled.div`
  margin-bottom: 0.3rem;
`;

const IconStyled = styled(Icon)`
  margin-right: 0.3rem;
`;

export const Tilskuddsbrev: React.FC<TilskuddsbrevProps> = ({ kildetabell, kildeId }) => {
  const [tilskuddsbrev, setTilskuddsbrev] = React.useState<TilskuddsbrevModel[]>();
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const get = async () => {
      try {
        const client = new TilskuddsbrevClient();
        const result = await client.getBrevFromTilskudd(kildetabell, kildeId);
        setTilskuddsbrev(result);
      } catch {
        setError(true);
      }
    };
    get();
  }, [kildeId, kildetabell]);

  if (error === true) {
    return <div>Kan for øyeblikket ikke laste brev.</div>;
  }

  if (tilskuddsbrev === undefined) return <Placeholder />;

  if (tilskuddsbrev.length === 0) return <div>Ingen brev på dette grunnlaget.</div>;

  return (
    <Grid>
      {tilskuddsbrev.map((brev) => (
        <div key={brev.externalId}>
          <Date>{formatDate(brev.datoEksportert, "dd.MM.yyyy")}</Date>
          <Brev>
            <IconStyled icon="text document" />
            <span>
              <a href={`/brev/lastnedbrev/${brev.externalId}/${false}`}>{brev.tittel}</a>
            </span>
          </Brev>
          {brev.vedlegg.map((vedlegg) => (
            <Brev key={vedlegg.externalId}>
              <IconStyled icon="text document" />
              <span>
                <a href={`/brev/lastnedbrev/${vedlegg.externalId}/${true}`}>{vedlegg.tittel}</a>
              </span>
            </Brev>
          ))}
        </div>
      ))}
    </Grid>
  );
};
