import {
  usePageViewTracking,
  userRoleDimension,
  pageAccessDimension,
  Dimension
} from "@utdanningsdirektoratet/hooks/usePageViewTracking";
import { getCurrentUser } from "./globalFunctions";
import { useContextState } from "../StateProvider";

const getUserRoleDimension = (): Dimension => {
  const currentUser = getCurrentUser()
  let userRoleText = "";
  if (currentUser && currentUser.roller.length === 0) {
    userRoleText = "Ingen roller"
  } else if (currentUser) {
    userRoleText = currentUser.roller.join(", ")
  }
  return userRoleDimension(userRoleText)
}

export const usePageViewTrackingWithoutAccessInfo = () => {
  const dimensions = [getUserRoleDimension()];

  usePageViewTracking(dimensions);
}

export const usePageViewTrackingInfoPage = (analyticsMessage?: string) => {
  let dimensions: Array<Dimension> = [];
  if (analyticsMessage) {
    dimensions = [getUserRoleDimension(), pageAccessDimension(analyticsMessage)];
  } else {
    dimensions = [pageAccessDimension(undefined)];
  }

  usePageViewTracking(dimensions);
}

export const usePageViewTrackingWithAccessInfo = () => {
  const [state] = useContextState();
  let accessText = "Ikke lese- eller skrivetilgang";
  if (state.altinn?.hasRead) {
    accessText = "Lesetilgang"
  }
  if (state.altinn?.hasReadWrite) {
    accessText = "Lese- og skrivetilgang"
  }

  let dimensions: Array<Dimension> = [];
  if (state.altinn) {
    dimensions = [getUserRoleDimension(), pageAccessDimension(accessText)];
  } else {
    dimensions = [pageAccessDimension(undefined)];
  }

  usePageViewTracking(dimensions);
}
