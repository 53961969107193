import useSWR from "swr";
import { DetailedList } from "@utdanningsdirektoratet/detailedlist";
import { Link as UdirLink } from "@utdanningsdirektoratet/link";
import { Heading2 } from "@utdanningsdirektoratet/styles";
import { Link, useParams } from "react-router-dom";
import { PagePanel } from "../layout/PagePanels";
import { AktorHeaderBox } from "./Dashboard/components/AktorHeaderBox";
import { Container } from "./Dashboard/components/Saker/SakStyles";
import { OrgNrParamType, useCurrentOrganization } from "../utils/globalFunctions";
import routes from "../routes";
import {
  PublisertInnsamlingStatus,
  SkolesoknadClient,
  SoknadMetadataViewModel,
  StatusEnum,
  TilskuddInnsamlingClient,
  TilsynClient,
  TilsynsStatus,
} from "../ApiClients";
import { HFLSWRStandardOptions } from "../utils/swrUtils";
import AvsluttetSakRow from "../components/AvsluttetSakRow";
import { mergeAndGroupAvsluttedeSaker } from "../utils/sakerUtils";

const isAvsluttetSoknad = (soknad: SoknadMetadataViewModel) => {
  if (soknad.lastStatus.status === StatusEnum.FerdigBehandlet) return true;
  if (soknad.lastStatus.status === StatusEnum.Withdrawn) return true;
  if (soknad.lastStatus.status === StatusEnum.Imported) {
    if (soknad.allStatus.some((s) => s.status === StatusEnum.Withdrawn)) {
      return true;
    }
    return false;
  }
  return false;
};

export const AvsluttedeSaker = () => {
  const { orgnr } = useParams<OrgNrParamType>();
  const currentOrganization = useCurrentOrganization();

  const { data: activeSoknads } = useSWR(
    !currentOrganization?.udirAktorId ? null : `endringSoknad/all/${currentOrganization?.udirAktorId}`,
    () => new SkolesoknadClient().getAllSoknads(currentOrganization!.udirAktorId!),
    HFLSWRStandardOptions,
  );

  const { data: publishedInnsamlinger } = useSWR(
    !currentOrganization?.udirAktorId ? null : `innsamlinger/active/${currentOrganization?.udirAktorId}`,
    () => new TilskuddInnsamlingClient().getInnsamlingerForAktor(currentOrganization!.udirAktorId!),
    HFLSWRStandardOptions,
  );

  const { data: publishedTilsyns } = useSWR(
    orgnr ? `tilsyns/${currentOrganization?.udirAktorId}` : null,
    () => new TilsynClient().getTilsyns(orgnr!),
    HFLSWRStandardOptions,
  );

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const avsluttedeSoknader = (activeSoknads ?? []).filter((s) => isAvsluttetSoknad(s));
  const avsluttedeInnsamlinger = (publishedInnsamlinger ?? []).filter((i) => i.status === PublisertInnsamlingStatus.Avsluttet);
  const avsluttedeTilsyn = (publishedTilsyns ?? []).filter((t) => t.status === TilsynsStatus.TilsynAvsluttet);

  const avsluttedeGroupedByYear = mergeAndGroupAvsluttedeSaker(avsluttedeSoknader, avsluttedeInnsamlinger, avsluttedeTilsyn);

  return (
    <>
      <AktorHeaderBox>
        <UdirLink iconLeft="angle left" text="Tilbake">
          {(props) => <Link {...props} to={routes.dashboard(orgnr!, "Oversikt")} />}
        </UdirLink>
      </AktorHeaderBox>
      <PagePanel>
        <Container>
          <Heading2 className="u--marginTop2">Avsluttede saker</Heading2>
          {Object.keys(avsluttedeGroupedByYear).map((year) => (
            <DetailedList key={year} title={year} className="u--marginTop2">
              {avsluttedeGroupedByYear[year].map((row, index) => (
                <AvsluttetSakRow key={index} row={row} />
              ))}
            </DetailedList>
          ))}
        </Container>
      </PagePanel>
    </>
  );
};
