import React from "react";
import styled from "styled-components";
import { BeregningsTable } from "./BeregningsTable";
import { Tilskuddsgrunnlag } from "../../../../../ApiClients";
import { PaddedHeading2 } from "../../../../../components/Headings";
import { formatCurrency } from "../../../../../utils/numberUtils";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const SumWrapper = styled.div`
  display: flex;
  column-gap: 1rem;
`;

type BeregningProps = {
  tilskuddsGrunnlag: Tilskuddsgrunnlag;
  className?: string;
};

export const Beregning: React.FC<BeregningProps> = ({ className, tilskuddsGrunnlag }) => {
  if (!tilskuddsGrunnlag.tilskuddsberegningDetails.data || tilskuddsGrunnlag.tilskuddsberegningDetails.data.length === 0) {
    if (tilskuddsGrunnlag.sum && tilskuddsGrunnlag.sum !== 0) {
      return (
        <SumWrapper>
          <div className="u--bold">Sum:</div>
          <div>{formatCurrency(tilskuddsGrunnlag.sum)}</div>
        </SumWrapper>
      );
    }
    return null;
  }

  return (
    <Wrapper className={className}>
      <PaddedHeading2>Beregning</PaddedHeading2>
      <BeregningsTable
        tilskuddsberegning={
          tilskuddsGrunnlag.tilskuddsberegningDetails // TODO
        }
      />
    </Wrapper>
  );
};
