import React from "react";
import { Heading2 } from "@utdanningsdirektoratet/styles";
import { ElevplassgruppeVgsModel, FriskoleVgsModel, ProgramomradeModel2, TilbudClient } from "../../../../../ApiClients";
import Elevplassgruppe from "./ElevplassGruppe";
import { ProgramomradeTable } from "./ProgramomradeTable";
import Section from "../../../../../components/Section";
import { TitleSubtitle, TotalsRow } from "../../SharedComponents";

type ElevtallVgsProps = {
  vgs?: FriskoleVgsModel | null;
};

const getTitle = (kapasitet: ElevplassgruppeVgsModel): string => {
  if (kapasitet.customTitle) return kapasitet.customTitle;

  const titles: Array<string> = [];
  kapasitet.programomrader?.forEach((programomrade) => {
    programomrade.utdanningsprograms?.forEach((u) => {
      if (!titles.includes(u.title ?? "")) {
        titles.push(u.title ?? "");
      }
    });
  });

  return titles.join(", ");
};

const ElevtallVgs: React.FC<ElevtallVgsProps> = ({ vgs }) => {
  const [programomrader, setprogramomrader] = React.useState<Array<ProgramomradeModel2>>([]);

  React.useEffect(() => {
    if (!vgs) return;
    const client = new TilbudClient();
    client.getProgramomrader().then(setprogramomrader);
  }, [vgs]);

  if (!vgs) return null;

  const elevplassgrupper = vgs.elevplassgrupper?.map((kapasitet) => {
    const title = getTitle(kapasitet);
    return (
      <Elevplassgruppe title={title} total={kapasitet.total} key={kapasitet.id}>
        <ProgramomradeTable programomradeKoder={kapasitet.programomrader?.map((p) => p.code ?? "") ?? []} allProgramomrader={programomrader} />
      </Elevplassgruppe>
    );
  });

  return (
    <Section>
      <Heading2>Elevplasser videregående skole</Heading2>
      <TotalsRow>
        <TitleSubtitle title="Total" subtitle={vgs.ordinar + vgs.spesial} />
        <TitleSubtitle title="Ordinære" subtitle={vgs.ordinar} />
        <TitleSubtitle title="Særskilt tilrettelagte" subtitle={vgs.spesial} />
      </TotalsRow>
      {elevplassgrupper}
    </Section>
  );
};

export default ElevtallVgs;
