import { ReactNode } from "react";
import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";
import { ColumnDefinitionType } from "./commonTypes";

type SimpleTableRowsProps<T> = {
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T>>;
};

const Td = styled.td<{ textAlign?: string; fontWeight: string }>`
  padding: 0.5rem 1rem;
  text-align: ${(props) => props.align};
  font-weight: ${(props) => props.fontWeight};
`;

const Tr = styled.tr`
  background-color: var(--lightgrey);
`;

const SumTr = styled.tr`
  border-top: 1px solid black;
`;

const TableFooter = styled.tfoot`
  border-top: 0.125rem solid ${colors.bordergrey};
`;

const SimpleTableRows = <T,>({ data, columns }: SimpleTableRowsProps<T>): JSX.Element => {
  const rows = data.map((row, index) => {
    return (
      <Tr key={`row-${index}`}>
        {columns
          .filter((column) => !column.hidden)
          .map((column, index2) => {
            const styleWidth = {
              width: column.width ?? 100,
            };
            let item;
            if (column.data) {
              item = column.data(row[column.key], row);
            } else {
              item = row[column.key];
            }
            return (
              <Td key={`cell-${index2}`} style={styleWidth} fontWeight={column.bold ? "bold" : ""}>
                <div className={column.alignRight ? "u--alignRight" : ""}>{item as ReactNode}</div>
              </Td>
            );
          })}
      </Tr>
    );
  });

  const hasSum = columns.some((c) => c.sum);

  let footer = null;

  if (hasSum) {
    footer = (
      <TableFooter key="row-sum">
        <SumTr>
          {columns
            .filter((column) => !column.hidden)
            .map((column, index) => {
              const styleWidth = {
                width: column.width ?? 100,
              };

              const item = column?.sum ?? "";
              return (
                <Td key={`cell-sum-${index}`} style={styleWidth} fontWeight="bold">
                  <div className={column.alignRight ? "u--alignRight" : ""}>{item as ReactNode}</div>
                </Td>
              );
            })}
        </SumTr>
      </TableFooter>
    );
  }

  return (
    <>
      <tbody>{rows}</tbody>
      {footer}
    </>
  );
};

export default SimpleTableRows;
