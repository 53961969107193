import React from "react";
import { InfoPage } from "./InfoPage";
import { AltinnInfoLink } from "./Dashboard/components/SharedComponents";

export const UnauthorizedPage: React.FC = () => {
  return (
    <InfoPage analyticsMessage="Ikke tilgang til virksomhet">
      Det ser ikke ut til at du har tilgang hit. Har du valgt riktig virksomhet? For å få tilgang til informasjon om en virksomhet i Tavla, må du være
      tilknyttet virksomheten i Altinn med nødvendige tilganger/rettigheter. Du kan finne mer informasjon om dette ved å gå til Udir sin{" "}
      <AltinnInfoLink>informasjonsside</AltinnInfoLink> om tilgangsstyring i Altinn. For å kunne logge inn som skoleeier for en virksomhet i Tavla, må
      du ha én av følgende tilganger på vegne av virksomheten i Altinn:
      <ul>
        <li>Tavla - Lesetilgang</li>
        <li>Tavla - Skrive- og lesetilgang</li>
      </ul>
    </InfoPage>
  );
};
