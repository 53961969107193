import { Dropdown, DropdownItem } from "@utdanningsdirektoratet/dropdown";
import React from "react";
import routes from "../routes";
import { getCurrentUser } from "../utils/globalFunctions";

export const UserSignoutDropdown: React.FC = () => {
  const signoutLink = [
    <DropdownItem closeOnClick>
      {({ className }) => (
        <a href={routes.signOutUidp} className={className}>
          Logg ut
        </a>
      )}
    </DropdownItem>,
  ];
  const user = getCurrentUser();

  if (!user) {
    return null;
  }
  return <Dropdown lists={[signoutLink]}>{getCurrentUser()?.fullName}</Dropdown>;
};
