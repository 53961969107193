import React from "react";
import {
  DetailedList,
  DetailedListCell,
  DetailedListRow,
  ExpandableDetailedListContent,
  ExpandableDetailedListRow,
} from "@utdanningsdirektoratet/detailedlist";
import { colors } from "@utdanningsdirektoratet/styles";
import styled from "styled-components";
import { isAfter } from "date-fns/isAfter";
import { parseISO } from "date-fns/parseISO";
import { Betalingsoppsummering } from "./Betalingsoppsummering";
import { formatCurrency } from "../../../../../utils/numberUtils";
import { formatLongDate } from "../../../../../utils/dateUtils";
import { PaddedHeading2 } from "../../../../../components/Headings";
import { TilskuddsDetaljer } from "../../../../../ApiClients";

const ExpandedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  background-color: ${colors.white};
`;

type ForfallsplanProps = {
  tilskuddsDetaljer: TilskuddsDetaljer;
  tilskuddGodkjent: boolean;
  className?: string;
};

export const Forfallsplan: React.FC<ForfallsplanProps> = ({ tilskuddsDetaljer, tilskuddGodkjent, className }) => {
  const rows = tilskuddsDetaljer.forfallsplan
    .sort((f1, f2) => (isAfter(parseISO(f1.dato), parseISO(f2.dato)) ? 1 : -1))
    .map((forfall) => {
      const isBelopNegative = forfall.totalBelop < 0;

      const utbetaltInnbetalt = forfall.isUtbetalt ? (
        <DetailedListCell size="80" right>
          {isBelopNegative ? "Innbetalt" : "Utbetalt"}
        </DetailedListCell>
      ) : null;

      const ikkeUtbetaltForfall = forfall.isGodkjent ? formatCurrency(forfall.totalBelop) : <span>Udir beregner</span>;

      return (
        <ExpandableDetailedListRow key={`forfall-${forfall.dato}-${forfall.forfallForBetaling[0].tilskuddsgrunnlagId}`}>
          <DetailedListRow key={`forfall-${forfall.dato}-${forfall.totalBelop}`}>
            <DetailedListCell>
              {forfall.isUtbetalt || forfall.isGodkjent || isBelopNegative ? null : "ca. "}
              {formatLongDate(new Date(forfall.dato))}
            </DetailedListCell>
            {utbetaltInnbetalt}
            <DetailedListCell size="140" right bold className={isBelopNegative && (forfall.isGodkjent || forfall.isUtbetalt) ? "u--darkred" : ""}>
              {forfall.isUtbetalt ? formatCurrency(forfall.totalBelop) : ikkeUtbetaltForfall}
            </DetailedListCell>
          </DetailedListRow>
          <ExpandableDetailedListContent>
            {forfall.forfallForBetaling.map((betaling, index) => (
              <ExpandedDiv key={`betaling-${index}`}>
                <div>{betaling.tilskuddsgrunnlagNavn}</div>
                <div>{forfall.isUtbetalt || forfall.isGodkjent ? formatCurrency(betaling.belop) : "Udir beregner"}</div>
              </ExpandedDiv>
            ))}
          </ExpandableDetailedListContent>
        </ExpandableDetailedListRow>
      );
    });
  return (
    <div className={className}>
      <PaddedHeading2>Forfallsplan</PaddedHeading2>
      <DetailedList>{rows}</DetailedList>
      <Betalingsoppsummering tilskuddDetaljer={tilskuddsDetaljer} terminplanGodkjent={tilskuddGodkjent} />
    </div>
  );
};
