import { Heading1, Heading3 } from "@utdanningsdirektoratet/styles/Headings";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Page = styled.div`
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.5rem;
`;

const PaddedHeading1 = styled(Heading1)`
  padding: 1.5rem 0 2.5rem;
`;

const PaddedHeading3 = styled(Heading3)`
  display: block;
  padding-bottom: 1.25rem;
`;

const NavLinkWrapper = styled.div`
  padding: 1rem 0;
`;

export const Personvern: React.FC = () => {
  return (
    <Page>
      <NavLinkWrapper>
        <NavLink to="/">Til forsiden</NavLink>
      </NavLinkWrapper>
      <PaddedHeading1>Personvernerklæring for Tavla</PaddedHeading1>
      <p>
        I Tavla kan representanter for private skoler, folkehøgskoler, kommuner, fylkeskommuner og andre aktører som mottar tilskudd eller
        prosjektmidler fra Utdanningsdirektoratet få oversikt over sine tilskudd og prosjektmidler. Private skoler kan i tillegg se tilsyn,
        godkjenninger, rapportere elevtall og søke om driftsendringer. Virksomheten kan ha flere brukere i Tavla, og vurderer selv hvem som trenger
        tilgang og på hvilket nivå. For å logge inn på Tavla må du enten ha en Altinn-rolle i virksomheten, eller ha blitt tildelt en tilgang i
        Altinn. Revisor må ha tilgang for å godkjenne og gi særattestasjon i forbindelse med elevtellinger. Revisorfirmaet som er registrert på skolen
        i Enhetsregisteret kan delegere rollene til sine ansatte.
      </p>
      <PaddedHeading3>Hvorfor vi behandler personopplysninger</PaddedHeading3>
      <p>
        Utdanningsdirektoratets behandlingsgrunnlag er allmenhetens interesse og utøvelse av offentlig myndighet, jf. personvernforordningen art. 6
        nr. 1 bokstav e og art. 9 nr. 2 bokstav g. Supplerende rettsgrunnlag privatskoleloven §§ 6-1 og 7-2, forskrift til privatskoleloven §§ 13-1 og
        13-2, økonomiforskrift til privatskoleloven §§ 4-6 og 4-7 og forskrift til folkehøyskoleloven § 7, samt tildelingsbrev.
      </p>
      <PaddedHeading3>Personopplysninger som behandles</PaddedHeading3>
      <p>Utdanningsdirektoratet er ansvarlig for behandling av følgende personopplysninger i systemet:</p>
      <p>
        <b>Ved innlogging:</b> navn, fødselsnummer, IP-adresse og virksomheter du er tilknyttet med Altinn-rolle.
      </p>
      <p>
        <b>Ved innsamling:</b> navn (ved endring, innsending, ledergodkjenning og revisorgodkjenning), vedlegg og kommentarer.
      </p>
      <PaddedHeading3>Hvem har tilgang til personopplysninger</PaddedHeading3>
      <p>
        Utdanningsdirektoratet drifter og utvikler systemet med bistand fra leverandør, Bekk Consulting AS, på den tekniske forvaltningen og
        utviklingen av systemene. Som drifter av systemet vil Utdanningsdirektoratet og leverandør kunne ha tilgang til personopplysninger i systemet.
        Leverandør er bundet av taushetserklæringer og databehandleravtaler. Systemet driftes internt i Utdanningsdirektoratet og kjører i Microsoft
        Azure i Vest-Europa. Twilio Sendgrid brukes til utsending av bekreftelseseposter, og din e-postadresse kan overføres til USA. Twilio er
        godkjent under den nye Data Privacy Framework.
      </p>
      <PaddedHeading3>Lagring og sletting av personopplysninger</PaddedHeading3>
      <p>
        Personopplysninger lagres så lenge det er nødvendige for å oppfylle formålet med verktøyet, samt etterleve arkivloven og andre lovpålagte
        bestemmelser. Logger knyttet til pålogging i verktøyet slettes etter 180 dager, men ikke historiske data om roller brukeren har hatt eller
        data/handlinger som er utført i verktøyet.
      </p>
      <p>
        Innsamlinger skal bevares for ettertiden. Derfor arkiverer vi innsamlingen inkludert navn på innsender og/eller godkjenner(e), vedlegg og
        kommentarer jf. arkivloven § 1.
      </p>
      <PaddedHeading3>Informasjonskapsler</PaddedHeading3>
      <p>
        Tavla bruker informasjonskapsler (cookies). Nødvendige informasjonskapsler brukes for at du skal kunne logge inn i tjenesten og navigere rundt
        i tjenesten basert på tilgangene du har. Statistiske informasjonskapsler brukes for å vite hvor på siden feil oppstår og å samle inn
        anonymiserte data for analyse av bruksmønsteret på nettsiden. De anonymiserte dataene inkluderer informasjon om operativsystem, nettleser,
        datamaskin/smarttelefon, hvilke lenker du klikker på og din geografiske plassering ned til nærmeste by. Kun Utdanningsdirektoratet, leverandør
        og vårt analyseverktøy har tilgang til informasjonen. Informasjonen kan ikke brukes til å spore deg. Dersom du ikke ønsker at vi skal samle
        inn denne informasjonen, kan du skru av statistiske informasjonskapsler ved å klikke på «informasjonskapsler» lengst ned på siden og endre
        innstillingene.
      </p>
      <PaddedHeading3>Hvis du har spørsmål</PaddedHeading3>
      <p>
        Har du spørsmål knyttet til behandling av dine personopplysninger i Tavla, kan du ta kontakt med{" "}
        <a href="mailto:personvernombudet@udir.no" target="_blank" rel="noopener noreferrer">
          personvernombudet@udir.no
        </a>
        . Du har rett til å klage til Datatilsynet. Informasjon finner du på{" "}
        <a href="https://www.datatilsynet.no/om-datatilsynet/kontakt-oss/klage-til-datatilsynet/" target="_blank" rel="noopener noreferrer">
          https://www.datatilsynet.no/om-datatilsynet/kontakt-oss/klage-til-datatilsynet/
        </a>
      </p>
    </Page>
  );
};
