import React from "react";
import { ColumnDefinitionType } from "../../SimpleTable/commonTypes";
import { ZebraSimpleTable } from "../../SimpleTable/StyledSimpleTable";
import { TilskuddsdataDetails, VisningsType } from "../../../../../ApiClients";
import { convertDecimalToPercentInString, formatCurrency, parseNorwegianFloat } from "../../../../../utils/numberUtils";

type GrunnlagsTableProps = {
  grunnlag: TilskuddsdataDetails;
};

export const GrunnlagsTable: React.FC<GrunnlagsTableProps> = ({ grunnlag }) => {
  // Den skjønner ikke at columns har keys som matcher data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const columns: Array<ColumnDefinitionType<typeof grunnlag>> = grunnlag.kolonner.map((kolonne) => ({
    key: kolonne.kolonneNavn,
    header: kolonne.kolonneNavn,
    sum: kolonne.sum ? formatCurrency(kolonne.sum) : undefined,
    alignRight: kolonne.visningsType !== VisningsType.Tekst,
    data: (item: string) => {
      if (kolonne.visningsType === VisningsType.Desimaltall || kolonne.visningsType === VisningsType.Heltall) {
        return item === "" || item === undefined || item === null ? "-" : formatCurrency(parseNorwegianFloat(item));
      }
      if (kolonne.visningsType === VisningsType.Prosent) {
        return convertDecimalToPercentInString(item);
      }
      return item;
    },
  }));

  return (
    <ZebraSimpleTable
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      data={grunnlag.tilskuddsdata}
    />
  );
};
