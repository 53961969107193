import React from "react";
import styled from "styled-components";
import { GrunnlagsTable } from "./GrunnlagsTable";
import { Tilskuddsgrunnlag } from "../../../../../ApiClients";
import { PaddedHeading2 } from "../../../../../components/Headings";

const Wrapper = styled.div`
  width: 100%;
`;

type GrunnlagProps = {
  tilskuddsGrunnlag: Tilskuddsgrunnlag;
  className?: string;
};

export const Grunnlag: React.FC<GrunnlagProps> = ({ className, tilskuddsGrunnlag }) => {
  if (!tilskuddsGrunnlag.tilskuddsdataDetails.tilskuddsdata || tilskuddsGrunnlag.tilskuddsdataDetails.tilskuddsdata.length === 0) {
    return null;
  }
  return (
    <Wrapper className={className}>
      <PaddedHeading2>Grunnlag</PaddedHeading2>
      <GrunnlagsTable grunnlag={tilskuddsGrunnlag.tilskuddsdataDetails} />
    </Wrapper>
  );
};
