import React from "react";
import styled from "styled-components";
import { ProgramomradeModel2 } from "../../../../../ApiClients";

type ProgramomradeTableProps = {
  programomradeKoder: Array<string>;
  allProgramomrader: Array<ProgramomradeModel2>;
};

const Table = styled.table`
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
`;

const Body = styled.tbody`
  border: 0.0125rem solid;
`;

const Tr = styled.tr`
  border-bottom: 0.0125rem solid;
`;

const Td = styled.td<{ width?: string }>`
  max-width: ${(props) => props.width};
  padding: 0.5rem 0.75rem;
`;

const Th = styled.th`
  text-align: left;
  padding: 0.25rem 0.75rem;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 0.875rem;
  border: none;
`;

export const ProgramomradeTable: React.FC<ProgramomradeTableProps> = ({ programomradeKoder, allProgramomrader }) => {
  return (
    <Table>
      <thead>
        <tr>
          <Th>Trinn</Th>
          <Th>Programområde</Th>
          <Th>Kode</Th>
        </tr>
      </thead>
      <Body>
        {programomradeKoder.map((programomrade) => {
          const completeProgramomrade = allProgramomrader?.find((p) => p.kode === programomrade);
          if (completeProgramomrade === undefined) return null;
          return (
            <Tr key={completeProgramomrade.kode}>
              <Td width="5rem">{completeProgramomrade.aarstrinn}</Td>
              <Td>{completeProgramomrade.tittel}</Td>
              <Td width="7rem">{completeProgramomrade.kode}</Td>
            </Tr>
          );
        })}
      </Body>
    </Table>
  );
};
