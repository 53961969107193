import * as React from "react";
import { ActionTypeProps, initialState, ReducerStateProps } from "./reducers";

export type UseStateProps = [ReducerStateProps, React.Dispatch<ActionTypeProps>];

export const StateContext = React.createContext<UseStateProps>([initialState, () => null]);
export const useContextState = (): UseStateProps => React.useContext(StateContext);

type StateProviderProps = {
  reducer: React.Reducer<ReducerStateProps, ActionTypeProps>;
  initState: ReducerStateProps;
  children: React.ReactNode;
};

const StateProvider: React.FC<StateProviderProps> = ({ reducer, initState, children }) => {
  return (
    <StateContext.Provider value={React.useReducer<React.Reducer<ReducerStateProps, ActionTypeProps>>(reducer, initState)}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
