import React, { ReactNode } from "react";
import styled from "styled-components";
import useSWRImmutable from "swr/immutable";
import { useCurrentOrganization } from "../../../utils/globalFunctions";
import { AktorClient } from "../../../ApiClients";
import { bankkontoNrFormatter, orgNrFormatter, telefonNrFormatter } from "../../../utils/formatters";

type AktorFooterProps = {
  children?: ReactNode;
};

const Wrapper = styled.div`
  padding: 1rem 2rem;
  background-color: var(--steelblue4);
  border-bottom: 1px solid var(--steelblue5);
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
  overflow: hidden;
`;

const RowItem = styled.div`
  flex: 1;
  padding: 0.5rem 1rem;
  max-width: 50rem;
  border: 1px solid var(--steelblue5);
`;

const Title = styled.div`
  font-weight: bold;
`;

const TitleSubtitle: React.FC<{ title: string; subtitle: string | undefined | null }> = ({ title, subtitle }) => {
  return (
    <RowItem>
      <Title>{title}</Title>
      <div>{subtitle}</div>
    </RowItem>
  );
};

export const AktorFooter: React.FC<AktorFooterProps> = ({ children }) => {
  const currentOrganization = useCurrentOrganization();
  const { data: aktor } = useSWRImmutable(!currentOrganization?.udirAktorId ? null : `getAktor-${currentOrganization.udirAktorId}`, () =>
    new AktorClient().get(currentOrganization!.udirAktorId!)
  );

  return (
    <Wrapper>
      <Row>
        <TitleSubtitle title="E-post" subtitle={aktor?.epost} />
        <TitleSubtitle title="Telefon" subtitle={telefonNrFormatter(aktor?.telefonnummer)} />
        <TitleSubtitle title="Postadresse" subtitle={aktor?.postadresse} />
        <TitleSubtitle title="Organisasjonsnummer" subtitle={orgNrFormatter(aktor?.orgNr)} />
        <TitleSubtitle title="Bankkonto" subtitle={bankkontoNrFormatter(aktor?.bankkontonummer)} />
      </Row>
      {children}
    </Wrapper>
  );
};
