import React from "react";
import { Section } from "@utdanningsdirektoratet/layout";
import { Heading2 } from "@utdanningsdirektoratet/styles";
import styled from "styled-components";
import { Satsgruppe } from "./Satsgruppe";
import { SatsgruppeModel } from "../../../../../ApiClients";

const Spacer = styled.div`
  margin-top: 1rem;
`;

interface Props {
  satsgrupper?: Array<SatsgruppeModel> | null;
}

export const Elevplasser: React.FC<Props> = ({ satsgrupper }) => {
  if (satsgrupper == null) return null;

  const sum = satsgrupper.map((s) => s.total).reduce((a, b) => a + b, 0);

  return (
    <Section>
      <Heading2>Elevplasser</Heading2>
      <Spacer />
      <p>Totalt antall elevplasser = {sum}</p>
      <Spacer />
      {satsgrupper.map((s, i) => (
        <Satsgruppe key={i} satsgruppe={s} />
      ))}
    </Section>
  );
};
