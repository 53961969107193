import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Link as UdirLink } from "@utdanningsdirektoratet/link";
import { Tab, Tabs } from "@utdanningsdirektoratet/tabs";
import { DetailedList } from "@utdanningsdirektoratet/detailedlist";
import colors from "@utdanningsdirektoratet/styles/colors";
import { PagePanel } from "../../layout/PagePanels";
import Tilbud from "./components/Tilbud/Tilbud";
import {
  GodkjenningModel,
  PublisertInnsamlingStatus,
  SkolesoknadClient,
  StatusEnum,
  TilbudClient,
  TilskuddInnsamlingClient,
  TilsynClient,
  TilsynsStatus,
} from "../../ApiClients";
import { SkolesoknadLinkBox } from "./components/SkolesoknadLinkBox";
import { TilskuddOverview } from "./components/Tilskudd/TilskuddOverview";
import { TabTypes, useCurrentOrganization } from "../../utils/globalFunctions";
import { AktorHeaderBox } from "./components/AktorHeaderBox";
import { HFLSWRStandardOptions } from "../../utils/swrUtils";
import routes from "../../routes";
import { Container } from "./components/Saker/SakStyles";
import { useContextState } from "../../StateProvider";
import { AktiveSaker } from "./components/Saker/AktiveSaker";
import { PaddedHeading1 } from "../../components/Headings";
import { AktorFooter } from "./components/AktorFooter";
import { mergeAvsluttedeSaker } from "../../utils/sakerUtils";
import AvsluttetSakRow from "../../components/AvsluttetSakRow";

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [tilbud, setTilbud] = useState<Array<GodkjenningModel> | null>(null);
  const { orgnr, tab } = useParams<{ orgnr: string; tab: TabTypes }>();
  const currentOrganization = useCurrentOrganization();
  const [state] = useContextState();

  if (tab === undefined) {
    navigate(routes.dashboard(orgnr!, "Oversikt"));
  }

  const isReadyToFetchReadContent = currentOrganization?.udirAktorId && state.altinn?.hasRead;
  const isReadyToFetchWriteContent = currentOrganization?.udirAktorId && state.altinn?.hasReadWrite;

  const { data: allSoknads } = useSWR(
    isReadyToFetchWriteContent ? `endringSoknad/all/${currentOrganization?.udirAktorId}` : null,
    async () => {
      try {
        return await new SkolesoknadClient().getAllSoknads(currentOrganization!.udirAktorId!);
      } catch (e) {
        return [];
      }
    },
    HFLSWRStandardOptions,
  );
  const isLoadingAllSoknads = isReadyToFetchWriteContent && allSoknads === undefined;

  const { data: publishedInnsamlinger } = useSWR(
    isReadyToFetchWriteContent ? `innsamlinger/active/${currentOrganization?.udirAktorId}` : null,
    async () => {
      try {
        return await new TilskuddInnsamlingClient().getInnsamlingerForAktor(currentOrganization!.udirAktorId!);
      } catch (_) {
        return [];
      }
    },
    HFLSWRStandardOptions,
  );
  const isLoadingInnsamlinger = isReadyToFetchWriteContent && publishedInnsamlinger === undefined;

  const { data: publishedTilsyns } = useSWR(
    orgnr ? `tilsyns/${currentOrganization?.udirAktorId}` : null,
    async () => {
      try {
        return await new TilsynClient().getTilsyns(orgnr!);
      } catch (e) {
        return [];
      }
    },
    HFLSWRStandardOptions,
  );
  const isLoadingPublishedTilsyns = orgnr && publishedTilsyns === undefined;

  const { data: aarsregnskapstilsyn } = useSWR(
    isReadyToFetchWriteContent ? `aarsregnskapstilsyn/all/${currentOrganization?.udirAktorId}` : null,
    async () => {
      try {
        return await new SkolesoknadClient().getAarsregnskapstilsyns(currentOrganization!.udirAktorId!);
      } catch (e) {
        return [];
      }
    },
    HFLSWRStandardOptions,
  );
  const isLoadingAarsregnskapstilsyn = isReadyToFetchWriteContent && aarsregnskapstilsyn === undefined;

  useEffect(() => {
    if (!state.altinn?.hasRead && state.altinn?.orgNr) {
      navigate(routes.unauthorized(state.altinn?.orgNr));
      return;
    }

    if (!currentOrganization?.udirAktorId || !currentOrganization.isActive || !(currentOrganization?.isSchool ?? false)) {
      setTilbud(null);
      return;
    }
    const client = new TilbudClient();
    client.getTilbudForAktor(currentOrganization.udirAktorId).then(setTilbud);
  }, [
    state.altinn?.hasRead,
    state.altinn?.orgNr,
    currentOrganization?.isSchool,
    currentOrganization?.name,
    currentOrganization?.number,
    currentOrganization?.udirAktorId,
    navigate,
  ]);

  const avsluttedeSoknader = (allSoknads ?? []).filter(
    (s) => s.lastStatus.status === StatusEnum.FerdigBehandlet || s.lastStatus.status === StatusEnum.Withdrawn,
  );
  const avsluttedeInnsamlinger = (publishedInnsamlinger ?? []).filter((i) => i.status === PublisertInnsamlingStatus.Avsluttet);
  const avsluttedeTilsyn = (publishedTilsyns ?? []).filter((t) => t.status === TilsynsStatus.TilsynAvsluttet);
  const hasAvsluttede = avsluttedeInnsamlinger.length > 0 || avsluttedeSoknader.length > 0 || avsluttedeTilsyn.length > 0;
  const isGodkjentIkkeStartet = currentOrganization?.isGodkjentIkkeStartet ?? false;
  const allAvsluttedeSaker = mergeAvsluttedeSaker(avsluttedeSoknader, avsluttedeInnsamlinger, avsluttedeTilsyn);
  const top3AvsluttedeSaker = allAvsluttedeSaker.slice(0, 3);

  return (
    <>
      {isReadyToFetchReadContent && <AktorHeaderBox />}
      <Tabs outline>
        <Tab
          id="Oversikt"
          label="Min side"
          active={tab === "Oversikt"}
          onClick={() =>
            navigate({
              pathname: routes.dashboard(orgnr!, "Oversikt"),
            })
          }
        />
        <Tab
          id="Tilskudd"
          label="Tilskudd"
          active={tab === "Tilskudd"}
          onClick={() =>
            navigate({
              pathname: routes.dashboard(orgnr!, "Tilskudd"),
            })
          }
        />
        {currentOrganization?.isActive && (
          <Tab
            id="Tilbud"
            label="Tilbud"
            active={tab === "Tilbud"}
            onClick={() =>
              navigate({
                pathname: routes.dashboard(orgnr!, "Tilbud"),
              })
            }
          />
        )}
      </Tabs>
      {tab === "Oversikt" && state.altinn?.hasReadWrite && (
        <>
          <PagePanel>
            <AktiveSaker
              // Hvis listene selv er undefined kan det hende de ikke skal vises til bruker (basert på read/write-rettigheter).
              // Derfor brukes isLoading... for å indikere når de faktisk skal hentes, og de ikke er ferdighentet.
              soknader={isLoadingAllSoknads ? undefined : allSoknads ?? []}
              innsamlinger={isLoadingInnsamlinger ? undefined : publishedInnsamlinger ?? []}
              tilsyn={isLoadingPublishedTilsyns ? undefined : publishedTilsyns ?? []}
              aarsregnskapstilsyn={isLoadingAarsregnskapstilsyn ? undefined : aarsregnskapstilsyn ?? []}
            />
            {hasAvsluttede && (
              <Container>
                <PaddedHeading1>Avsluttede saker</PaddedHeading1>
                <DetailedList title="Siste avsluttede saker" className="u--marginBottom1">
                  {top3AvsluttedeSaker.map((sak, index) => (
                    <AvsluttetSakRow key={index} row={sak} />
                  ))}
                </DetailedList>
                {top3AvsluttedeSaker.length !== allAvsluttedeSaker.length && (
                  <UdirLink iconRight="angle right" text="Se alle avsluttede saker">
                    {(props) => <Link {...props} to={routes.avsluttedeSaker(orgnr!)} />}
                  </UdirLink>
                )}
              </Container>
            )}
          </PagePanel>
          {state.altinn?.hasReadWrite && currentOrganization?.isSchool && currentOrganization?.isActive && (
            <SkolesoknadLinkBox udirAktorId={currentOrganization?.udirAktorId} />
          )}
        </>
      )}

      {tab === "Tilskudd" && isReadyToFetchReadContent && <TilskuddOverview disabled={isGodkjentIkkeStartet} />}
      {tab === "Tilbud" && currentOrganization?.isActive && (
        <>
          <Tilbud tilbuds={tilbud} />
          {state.altinn?.hasReadWrite && currentOrganization?.isSchool && (
            <SkolesoknadLinkBox udirAktorId={currentOrganization?.udirAktorId} backgroundColor={colors.white} />
          )}
        </>
      )}
      {isReadyToFetchReadContent && <AktorFooter />}
    </>
  );
};
